import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

import "../resources/css/login-style.css";
import "../resources/css/login.css";
import swal from "sweetalert2";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ForgotPassword = () => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [type, setType] = useState("TF");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isactive, setIsActive] = useState(false);

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.resetPassword(username, type).then(
        (res) => {
          setIsActive(true);
          if (res.username === undefined) {
            // navigate("/home");
            swal.fire({
              position: "top",
              icon: "success",
              title: "Success",
              text: res.message,
              showConfirmButton: false,
              timer: 5000,
            });
            setLoading(false);
            // window.location.reload(true);
          } else {
            swal.fire({
              position: "top",
              icon: "error",
              title: "Error",
              text: res.message,
              showConfirmButton: false,
              timer: 5000,
            });
            setLoading(false);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="Main-container">
        <div
          className="container_1"
          style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
          <div className="wrap-login">
            <div className="login-pic">
              <img src="assets/img/logo/logo2.png" alt="IMG" />
            </div>
            <Form
              className="login-form"
              onSubmit={handlePasswordReset}
              ref={form}
            >
              <span className="login-form-title_" style={{ color: "#666" }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "inherit",
                    margin: "inherit",
                  }}
                >
                  Woodalls B2B Trade Portal
                </p>
              </span>
              <span
                className="login-title"
                style={{ color: "#666", textAlign: "center" }}
              >
                This portal is accessible to authorised trade customers with a
                Woodalls trade account only.
              </span>
              <span className="login-title" style={{ textAlign: "center" }}>
                Reset Your Password, enter your e-mail to get back into your
                account
              </span>
              <div className="wrap-input">
                <input
                  type="text"
                  className="input"
                  name="username"
                  placeholder="E-mail"
                  required
                  value={username}
                  onChange={onChangeUsername}
                />
                <span className="focus-input"></span>
                <span className="symbol-input">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
              </div>

              <div className="login-form-btn-container">
                <button
                  className="login-form-btn"
                  style={{ marginTop: "-20px" }}
                  disabled={loading}
                >
                  Reset &nbsp;
                  {loading && (
                    <span className="spinner-border spiner-border-sm align-right"></span>
                  )}
                </button>
              </div>

              {/* {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )} */}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
