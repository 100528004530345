import React, { useState, useEffect, useRef } from "react";
import UserService from "../services/user.service";
import { RiseLoader } from "react-spinners";
import swal from "sweetalert2";
import { loadScript } from "../utility/loadScriptUtil";
import $ from "jquery";

import Header from "../components/header-component";
import Footer from "../components/footer";

import {
  checkOutPOST,
  countryListGET,
  currencyListGET,
  getShoppingCartData,
  userDataGET,
} from "../services/apiServices";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

export default function CheckOut() {
  const form = useRef();
  const checkBtn = useRef();

  const [shoppingCartState, setShoppingCartState] = useState(null);
  const [shoppingCartItemsState, setShoppingCartItemsState] = useState(null);
  const [currencyState, setCurrencyState] = useState(null);
  const [countryState, setCountryState] = useState(null);
  const [userState, setUserState] = useState(null);
  const [selectedAddState, setSelectedAddState] = useState(null);
  const [addressState, setAddressState] = useState(null);
  const [cartloading, setCartLoading] = useState(false);

  const [cusPO, setCusPO] = useState("");
  const [orderType, setOrderType] = useState(0);
  const [isDiffAdd, setIsDiffAdd] = useState(false);
  const [country, setCountry] = useState(9);
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [addLine3, setAddLine3] = useState("");
  const [postCode, setPostCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  //get shopping cart data
  const getCartData = async () => {
    getShoppingCartData()
      .then((res) => {
        const cartItem = res.data.products;

        if (cartItem != null) {
          setShoppingCartState(res.data);
          setShoppingCartItemsState(cartItem);
        } else {
          setShoppingCartState(null);
          setShoppingCartItemsState(null);
        }

        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get currenvy data
  const getCurrency = async () => {
    currencyListGET()
      .then((res) => {
        setCurrencyState(res.data);
        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get currenvy data
  const getUser = async () => {
    setCartLoading(false);
    userDataGET()
      .then((res) => {
        setUserState(res.data);
        setAddressState(res.data.addresses);
        setSelectedAddState(res.data.addresses[0]);
        console.log(res.data.addresses[0]);
        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get country data
  const getCountry = async () => {
    countryListGET()
      .then((res) => {
        setCountryState(res.data);
        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //change address
  const changeAddress = (e) => {
    addressState.map((add) => {
      if (parseInt(e) === parseInt(add.addressId)) {
        setSelectedAddState(add);
      }
    });
  };

  //new address form data bind
  const onChangeIsDiffAdd = (e) => {
    setIsDiffAdd(e.target.checked);
    if (e.target.checked) {
      $("#ship-box-info").show("slow");
    } else {
      $("#ship-box-info").hide("slow");
    }
  };

  const onChangeCountry = (e) => {
    setCountry(e.target.value);
  };

  const onChangeAddLine1 = (e) => {
    setAddLine1(e.target.value);
  };

  const onChangeAddLine2 = (e) => {
    setAddLine2(e.target.value);
  };

  const onChangeAddLine3 = (e) => {
    setAddLine3(e.target.value);
  };

  const onChangePostCode = (e) => {
    setPostCode(e.target.value);
  };

  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangeNote = (e) => {
    setNote(e.target.value);
  };

  const onChangeOrderType = (e) => {
    setOrderType(e.target.value);
  };

  const onChangeCusPo = (e) => {
    setCusPO(e.target.value);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsgs = {};

    // if (cusPO == "") {
    //   formIsValid = false;
    //   errorMsgs["cusPO"] = "Customer PO is required.";
    // }

    if (orderType == 0) {
      formIsValid = false;
      errorMsgs["orderType"] = "Please Select Order Type.";
    }

    if (isDiffAdd === true) {
      if (!addLine1) {
        formIsValid = false;
        errorMsgs["addLine1"] = "Address Line 1 is required.";
      }

      if (!postCode) {
        formIsValid = false;
        errorMsgs["postCode"] = "PostCode is required.";
      }

      if (!phone) {
        formIsValid = false;
        errorMsgs["phone"] = "Phone is required.";
      }

      if (!email) {
        formIsValid = false;
        errorMsgs["email"] = "Email Address is required.";
      }
    }
    setErrors(errorMsgs);
    return formIsValid;
  };

  const clearError = (event) => {};

  //place order
  const placeOrder = (e) => {
    e.preventDefault();

    let addressData = null;
    if (handleValidation()) {
      if (isDiffAdd === true) {
        // if (isDiffAdd) {
        addressData = {
          line01: addLine1,
          line02: addLine2,
          line03: addLine3,
          postCode: postCode,
          country: { countryId: country },
        };
        checkOut(addressData);
        // }
      } else {
        checkOut(addressData);
      }
    }
  };

  const checkOut = (addressData) => {
    checkOutPOST(
      shoppingCartState.cartId,
      selectedAddState.addressId,
      addressData,
      orderType,
      cusPO,
      note,
      phone
    )
      .then((res) => {
        // swal("Success", "Your Order has been placed!", "success");
        swal.fire({
          position: "top",
          icon: "success",
          title: "Success",
          text: "Your Order has been placed!",
          showConfirmButton: false,
          timer: 1500,
        });
        getCartData();
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      });
  };

  useEffect(() => {
    (async () => {
      await getCountry();
      await getCurrency();
      await getCartData();
      await getUser();
      // loadScript();
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {!cartloading ? (
        <div className="spinner">
          <RiseLoader color="#263c97" size={20} />
        </div>
      ) : (
        <main>
          {/* page-banner-area-start */}
          {!cartloading ? (
            <RiseLoader color="#ffffff" size={10} />
          ) : shoppingCartState === null ? (
            <div
              className="page-banner-area page-banner-height-2"
              data-background="assets/img/banner/page-banner-4.jpg"
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="page-banner-content text-center">
                      <h4 className="breadcrumb-title">Checkout</h4>
                      <div className="breadcrumb-two">
                        <nav>
                          <nav className="breadcrumb-trail breadcrumbs">
                            <ul className="breadcrumb-menu">
                              <li className="breadcrumb-trail">
                                <a href="/home">
                                  <span>Home</span>
                                </a>
                              </li>
                              <li className="trail-item">
                                <span>Checkout</span>
                              </li>
                            </ul>
                          </nav>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <section className="breadcrumb__area box-plr-75">
              <div className="container">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="breadcrumb__wrapper">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/home">Home</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Cart
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* page-banner-area-end */}

          {/* checkout-area-start */}
          {!cartloading ? (
            <RiseLoader color="#ffffff" size={10} />
          ) : (
            <div>
              {shoppingCartItemsState != null ? (
                <section className="checkout-area pb-85">
                  <div className="container">
                    <Form onSubmit={placeOrder} ref={form}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="checkbox-form">
                            <h3>Billing Details</h3>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="order-notes">
                                  <div className="checkout-form-list">
                                    <div className="col-md-12">
                                      <div className="country-select">
                                        <label>Delivery Address</label>
                                        <select
                                          onChange={(e) =>
                                            changeAddress(e.target.value)
                                          }
                                        >
                                          {addressState != null
                                            ? addressState.map((el) => {
                                                return (
                                                  <option
                                                    key={el.addressId}
                                                    value={el.addressId}
                                                  >
                                                    {el.line01 +
                                                      ", " +
                                                      el.postCode}
                                                  </option>
                                                );
                                              })
                                            : ""}
                                        </select>
                                      </div>
                                    </div>

                                    <a
                                      style={{
                                        fontSize: "15px",
                                        fontStyle: "oblique",
                                        color: "#000",
                                      }}
                                    >
                                      {user.name}
                                      <br />
                                      {selectedAddState != null
                                        ? selectedAddState.line01
                                        : ""}
                                      {selectedAddState != null &&
                                      selectedAddState.line02 != null ? (
                                        <br />
                                      ) : (
                                        ""
                                      )}
                                      {selectedAddState != null &&
                                      selectedAddState.line02 != null
                                        ? selectedAddState.line02
                                        : ""}
                                      {selectedAddState != null &&
                                      selectedAddState.line03 != null ? (
                                        <br />
                                      ) : (
                                        ""
                                      )}
                                      {selectedAddState != null &&
                                      selectedAddState.line03 != null
                                        ? selectedAddState.line03
                                        : ""}
                                      <br />
                                      {selectedAddState != null
                                        ? selectedAddState.postCode
                                        : ""}

                                      <br />
                                      {user.mobile}
                                      <br />
                                      {user.email}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="different-address">
                              <div className="ship-different-title">
                                <h3>
                                  <label>Ship to a different address?</label>
                                  <input
                                    id="ship-box-custom"
                                    onChange={onChangeIsDiffAdd}
                                    type="checkbox"
                                  />
                                </h3>
                              </div>
                              <div id="ship-box-info">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="country-select">
                                      <label>
                                        Country
                                        <span className="required"> *</span>
                                      </label>
                                      <select
                                        style={{ display: "none" }}
                                        onChange={onChangeCountry}
                                      >
                                        {countryState != null
                                          ? countryState.map((c) => {
                                              return (
                                                <option
                                                  key={c.countryId}
                                                  value={c.countryId}
                                                >
                                                  {c.countryName}
                                                </option>
                                              );
                                            })
                                          : ""}
                                      </select>
                                      <div className="nice-select">
                                        <span className="current">
                                          {countryState != null
                                            ? countryState[0].countryName
                                            : ""}
                                        </span>
                                        <ul className="list">
                                          {countryState != null
                                            ? countryState.map((c) => {
                                                return (
                                                  <li
                                                    key={c.countryId}
                                                    data-value={c.countryId}
                                                    className="option selected"
                                                  >
                                                    {c.countryName}
                                                  </li>
                                                );
                                              })
                                            : ""}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="checkout-form-list">
                                      <label>
                                        Address Line 1
                                        <span className="required">*</span>{" "}
                                        <span className="error-span">
                                          {errors["addLine1"]}
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="addLine1"
                                        value={addLine1}
                                        onChange={onChangeAddLine1}
                                        onFocus={clearError("addLine1")}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="checkout-form-list">
                                      <label>Address Line 2</label>
                                      <input
                                        type="text"
                                        name="addLine2"
                                        value={addLine2}
                                        onChange={onChangeAddLine2}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="checkout-form-list">
                                      <label>Address Line 3</label>
                                      <input
                                        type="text"
                                        name="addLine3"
                                        value={addLine3}
                                        onChange={onChangeAddLine3}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="checkout-form-list">
                                      <label>
                                        Postcode / Zip
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="postCode"
                                        value={postCode}
                                        onChange={onChangePostCode}
                                      />
                                      <span className="error-span">
                                        {errors["postCode"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="checkout-form-list">
                                      <label>
                                        Phone{" "}
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="phone"
                                        value={phone}
                                        onChange={onChangePhone}
                                      />
                                      <span className="error-span">
                                        {errors["phone"]}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="checkout-form-list">
                                      <label>
                                        Email Address
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        name="postCode"
                                        value={email}
                                        onChange={onChangeEmail}
                                      />
                                      <span className="error-span">
                                        {errors["email"]}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="order-notes">
                                <div className="checkout-form-list">
                                  <label>Order Notes</label>
                                  <textarea
                                    id="checkout-mess"
                                    cols="30"
                                    rows="10"
                                    name="note"
                                    value={note}
                                    onChange={onChangeNote}
                                    placeholder="Notes about your order, e.g. special notes for delivery."
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="your-order mb-30 ">
                            <h3>Your order</h3>
                            <div className="your-order-table table-responsive">
                              <table>
                                <tbody></tbody>
                                <tfoot>
                                  <tr>
                                    <th>
                                      Customer PO
                                      {/* <span
                                        className="required"
                                        style={{ color: "red" }}
                                      >
                                        *
                                      </span> */}
                                    </th>
                                    <th>
                                      <input
                                        type="text"
                                        name="cusPO"
                                        value={cusPO}
                                        onChange={onChangeCusPo}
                                        onFocus={clearError("cusPO")}
                                      />
                                      <br />
                                      <span className="error-span">
                                        {errors["cusPO"]}
                                      </span>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>
                                      Order Type
                                      <span
                                        className="required"
                                        style={{ color: "red" }}
                                      >
                                        *
                                      </span>
                                    </th>
                                    <th>
                                      <select
                                        className="order-type-select"
                                        onChange={onChangeOrderType}
                                      >
                                        <option value={0}>-- Select --</option>
                                        <option value={1}>Sales</option>
                                        <option value={3}>Quotation</option>
                                      </select>
                                      <br />
                                      <span className="error-span">
                                        {errors["orderType"]}
                                      </span>
                                    </th>
                                  </tr>
                                  <tr className="cart-subtotal">
                                    <th>Net Amount</th>
                                    <th>
                                      <span className="amount">
                                        {UserService.numberFormat(
                                          shoppingCartState.cartTotal -
                                            (shoppingCartState.totalVat +
                                              shoppingCartState.totalDeliveyCost)
                                        )}
                                      </span>
                                    </th>
                                  </tr>
                                  <tr className="shipping">
                                    <th>Shipping</th>
                                    <th>
                                      <span className="amount">
                                        {UserService.numberFormat(
                                          shoppingCartState.totalDeliveyCost
                                        )}
                                      </span>
                                    </th>
                                  </tr>
                                  <tr className="cart-subtotal">
                                    <th>Vat Amount</th>
                                    <th>
                                      <span className="amount">
                                        {UserService.numberFormat(
                                          shoppingCartState.totalVat
                                        )}
                                      </span>
                                    </th>
                                  </tr>
                                  <tr className="order-total">
                                    <th>Order Total</th>
                                    <th>
                                      <strong>
                                        <span className="amount">
                                          {UserService.numberFormat(
                                            shoppingCartState.cartTotal
                                          )}
                                        </span>
                                      </strong>
                                    </th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>

                            <div className="payment-method">
                              <div
                                className="accordion"
                                id="checkoutAccordion"
                              ></div>
                              <div className="order-button-payment mt-20">
                                <CheckButton
                                  type="submit"
                                  className="tp-btn-h1"
                                  ref={checkBtn}
                                >
                                  Place order
                                </CheckButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </section>
              ) : (
                ""
              )}
            </div>
          )}
          {/* checkout-area-end */}
        </main>
      )}

      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
