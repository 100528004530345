import React, { useState, useRef, useEffect } from "react";

import "../resources/css/email.css";

const EmailVerification = () => {
  return (
    <React.Fragment>
      <div className="Main-container">
        <div className="container_1">
          <div className="emailstyle">
            <div className="emailpic">
              <img
                src="assets/img/email/email.png"
                alt="IMG"
                className="center"
                style={{ maxWidth: "150px" }}
              />
            </div>
            <div className="maintext">Verified your Email</div>
            <div className="subtext">
              {" "}
              We have Successfully Verified your email Address <br />
              <span>
                Please wait until the account is approved by Trade Team.
              </span>
            </div>
            <div className="email-form-btn-container">
              <a href="/" className="email-form-btn">
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailVerification;
