import React, { useState, useEffect } from "react";
import axios from "axios";
import UserService from "../services/user.service";

export default function Slider() {
  const [sliderState, setSliderState] = useState([]);
  const [promoOneState, setPromoOneState] = useState([]);
  const [promo1State, setPromo1State] = useState([]);
  const [promo2State, setPromo2State] = useState([]);
  const [promo3State, setPromo3State] = useState([]);

  // get slider data
  const sliderData = async () => {
    const res = await axios.get(UserService.api_Url + "api/app/banner/list/1", {
      headers: UserService.header,
    });
    setSliderState(res.data);
  };

  // get promotion data
  const promoData = async (type) => {
    const res = await axios.get(
      UserService.api_Url + "api/app/banner/list/" + type,
      {
        headers: UserService.header,
      }
    );
    if (type === 2) {
      setPromo1State(res.data);
    } else if (type === 3) {
      setPromo2State(res.data);
    } else if (type === 4) {
      setPromo3State(res.data);
    }
  };

  useEffect(() => {
    sliderData();
    promoData(2);
    promoData(3);
    promoData(4);
  }, []);

  return (
    <React.Fragment>
      {/* slider area start */}
      <div className="slider-area light-bg-s pt-60">
        <div className="container custom-conatiner">
          <div className="row">
            <div className="col-xl-7">
              <div className="swiper-container slider__active pb-30">
                <div className="slider-wrapper swiper-wrapper">
                  {sliderState.length > 0
                    ? sliderState.map((sl) => {
                        return (
                          <div
                            key={sl.bannerId}
                            className="single-slider swiper-slide b-radius-2 slider-height-2 d-flex align-items-center"
                            //data-background={sliderState[0].image}
                            style={{
                              backgroundImage: `url(${sl.image})`,
                            }}
                          >
                            <div className="slider-content slider-content-2">
                              <h2
                                data-animation="fadeInLeft"
                                data-delay="1.7s"
                                className="pt-15 slider-title pb-5"
                              >
                                {sl.title}
                              </h2>
                              <p
                                className="pr-20 slider_text"
                                data-animation="fadeInLeft"
                                data-delay="1.9s"
                              >
                                {sl.description}
                              </p>
                              {sl.url.length > 0 ? (
                                <div className="slider-bottom-btn mt-65">
                                  <a
                                    data-animation="fadeInUp"
                                    data-delay="1.15s"
                                    href={sl.url}
                                    className="st-btn-border b-radius-2"
                                  >
                                    Discover now
                                  </a>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })
                    : ""}

                  <div className="main-slider-paginations"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="row">
                {sliderState.length > 0 ? (
                  <React.Fragment>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="banner__item p-relative w-img mb-30">
                        <div className="banner__img b-radius-2">
                          <a href="product-details.html">
                            <img src={sliderState[0].image} alt="" />
                          </a>
                        </div>
                        <div className="banner__content banner__content-2">
                          <h6>
                            {sliderState[0].url.length ? (
                              <a href={sliderState[0].url}>
                                {sliderState[0].title}
                              </a>
                            ) : (
                              <a>{sliderState[0].title}</a>
                            )}
                          </h6>
                          <p>{sliderState[0].description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="banner__item p-relative w-img mb-30">
                        <div className="banner__img b-radius-2">
                          <a href="product-details.html">
                            <img src={sliderState[1].image} alt="" />
                          </a>
                        </div>
                        <div className="banner__content banner__content-2">
                          <h6>
                            {sliderState[1].url.length ? (
                              <a href={sliderState[1].url}>
                                {sliderState[1].title}
                              </a>
                            ) : (
                              <a>{sliderState[1].title}</a>
                            )}
                          </h6>
                          <p>{sliderState[1].description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="banner__item p-relative w-img mb-30">
                        <div className="banner__img b-radius-2">
                          <a href="product-details.html">
                            <img src={sliderState[2].image} alt="" />
                          </a>
                        </div>
                        <div className="banner__content banner__content-2">
                          <h6>
                            {sliderState[2].url.length ? (
                              <a href={sliderState[2].url}>
                                {sliderState[2].title}
                              </a>
                            ) : (
                              <a>{sliderState[2].title}</a>
                            )}
                          </h6>
                          <p>{sliderState[2].description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="banner__item p-relative w-img mb-30">
                        <div className="banner__img b-radius-2">
                          <a href="product-details.html">
                            <img src={sliderState[3].image} alt="" />
                          </a>
                        </div>
                        <div className="banner__content banner__content-2">
                          <h6>
                            {sliderState[3].url.length > 0 ? (
                              <a href={sliderState[3].url}>
                                {sliderState[3].title}
                              </a>
                            ) : (
                              <a>{sliderState[3].title}</a>
                            )}
                          </h6>
                          <p>{sliderState[3].description}</p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* slider area end */}

      {/* banner__area-start */}
      {/* <section className="banner__area light-bg-s pt-40 pb-10">
        <div className="container custom-conatiner">
          <div className="row">
            {promo1State.length > 0 ? (
              <React.Fragment>
                <div className="col-xl-4 col-lg-4 col-md-12">
                  <div className="banner__item p-relative w-img mb-30">
                    <div className="banner__img b-radius-2">
                      <a href="product-details.html">
                        <img
                          src={promo1State[promo1State.length - 1].image}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="banner__content banner__content-2">
                      <h6>
                        {promo1State[promo1State.length - 1].url.length > 0 ? (
                          <a href={promo1State[promo1State.length - 1].url}>
                            {promo1State[promo1State.length - 1].title}
                          </a>
                        ) : (
                          <a>{promo1State[promo1State.length - 1].title}</a>
                        )}
                      </h6>
                      <p className="sm-p">
                        Up To - {promo1State[promo1State.length - 1].rate}%
                      </p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}

            {promo2State.length > 0 ? (
              <React.Fragment>
                <div className="col-xl-4 col-lg-4 col-md-12">
                  <div className="banner__item p-relative mb-30 w-img">
                    <div className="banner__img b-radius-2">
                      <a href="product-details.html">
                        <img
                          src={promo2State[promo2State.length - 1].image}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="banner__content banner__content-2">
                      <h6>
                        {promo2State[promo2State.length - 1].url.length > 0 ? (
                          <a href={promo2State[promo2State.length - 1].url}>
                            {promo2State[promo2State.length - 1].title}
                          </a>
                        ) : (
                          <a>{promo2State[promo2State.length - 1].title}</a>
                        )}
                      </h6>
                      <p className="sm-p">
                        Up To - {promo2State[promo2State.length - 1].rate}%
                      </p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}

            {promo3State.length > 0 ? (
              <React.Fragment>
                <div className="col-xl-4 col-lg-4 col-md-12">
                  <div className="banner__item p-relative mb-30 w-img">
                    <div className="banner__img b-radius-2">
                      <a href="product-details.html">
                        <img
                          src={promo3State[promo3State.length - 1].image}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="banner__content banner__content-2">
                      <h6>
                        {promo3State[promo3State.length - 1].url.length > 0 ? (
                          <a href={promo3State[promo3State.length - 1].url}>
                            {promo3State[promo3State.length - 1].title}
                          </a>
                        ) : (
                          <a>{promo3State[promo3State.length - 1].title}</a>
                        )}
                      </h6>
                      <p className="sm-p">
                        Up To - {promo3State[promo3State.length - 1].rate}%
                      </p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </section> */}
      {/* banner__area-end */}
    </React.Fragment>
  );
}

// export default class Slider1 extends Component {

//     constructor() {
//         super();
//         this.state = {
//             sliderState: [],
//             isLoading: true
//         }
//     }

//     componentDidMount() {
//         this.sliderData();
//     }

//     // get slider data
//     sliderData = async () => {
//         const res = await axios
//             .get(
//                 UserService.api_Url + "api/app/banner/list/0",
//                 { headers: UserService.header }
//             );
//         this.setState({
//             sliderState: res.data
//         });
//     };

//     render() {
//         return (
//             <React.Fragment>
//                 {/* slider area start */}
//                 <div className="slider-area light-bg-s pt-60">
//                     <div className="container custom-conatiner">
//                         <div className="row">
//                             <div className="col-xl-7">
//                                 <div className="swiper-container slider__active pb-30">
//                                     <div className="slider-wrapper swiper-wrapper">
//                                         {sliderState.length > 0 ? (
//                                             sliderState.map(sl => {

//                                                 return (
//                                                     <div key={sl.bannerId} className="single-slider swiper-slide b-radius-2 slider-height-2 d-flex align-items-center"
//                                                     //data-background={sliderState[0].image}
//                                                         style={{
//                                                             backgroundImage: `url(${sl.image})`
//                                                         }}
//                                                     >
//                                                     <div className="slider-content slider-content-2">
//                                                         <h2 data-animation="fadeInLeft" data-delay="1.7s" className="pt-15 slider-title pb-5">
//                                                             {sl.title}
//                                                         </h2>
//                                                         <p className="pr-20 slider_text" data-animation="fadeInLeft" data-delay="1.9s">
//                                                             {sl.description}
//                                                         </p>
//                                                             {sl.url.length > 0 ?
//                                                                 <div className="slider-bottom-btn mt-65">
//                                                                     <a data-animation="fadeInUp" data-delay="1.15s" href={sl.url} className="st-btn-border b-radius-2">
//                                                                         Discover now
//                                                                     </a>
//                                                                 </div>
//                                                                 :""
//                                                             }
//                                                     </div>
//                                                 </div>
//                                                 )
//                                             })

//                                         ): ""
//                                         }

//                                         <div className="main-slider-paginations"></div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-xl-5">
//                                 <div className="row">
//                                     {sliderState.length > 0 ?
//                                         <React.Fragment>
//                                         <div className="col-xl-6 col-lg-6 col-md-6">
//                                             <div className="banner__item p-relative w-img mb-30">
//                                                 <div className="banner__img b-radius-2">
//                                                     <a href="product-details.html"><img src={sliderState[0].image} alt=""/></a>
//                                                 </div>
//                                                 <div className="banner__content banner__content-2">
//                                                     <h6>
//                                                         <a href="product-details.html">{sliderState[0].title}</a>
//                                                     </h6>
//                                                     <p>{sliderState[0].description}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-xl-6 col-lg-6 col-md-6">
//                                             <div className="banner__item p-relative w-img mb-30">
//                                                 <div className="banner__img b-radius-2">
//                                                     <a href="product-details.html"><img src={sliderState[1].image} alt=""/></a>
//                                                 </div>
//                                                 <div className="banner__content banner__content-2">
//                                                     <h6>
//                                                         <a href="product-details.html">{sliderState[1].title}</a>
//                                                     </h6>
//                                                     <p>{sliderState[1].description}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-xl-6 col-lg-6 col-md-6">
//                                             <div className="banner__item p-relative w-img mb-30">
//                                                 <div className="banner__img b-radius-2">
//                                                     <a href="product-details.html"><img src={sliderState[2].image} alt=""/></a>
//                                                 </div>
//                                                 <div className="banner__content banner__content-2">
//                                                     <h6>
//                                                         <a href="product-details.html">{sliderState[2].title}</a>
//                                                     </h6>
//                                                     <p>{sliderState[2].description}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-xl-6 col-lg-6 col-md-6">
//                                             <div className="banner__item p-relative w-img mb-30">
//                                                 <div className="banner__img b-radius-2">
//                                                     <a href="product-details.html"><img src={sliderState[3].image} alt=""/></a>
//                                                 </div>
//                                                 <div className="banner__content banner__content-2">
//                                                     <h6>
//                                                         <a href="product-details.html">{sliderState[3].title}</a>
//                                                     </h6>
//                                                     <p>{sliderState[3].description}</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         </React.Fragment>
//                                         :""
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 {/* slider area end */}

//                 {/* banner__area-start */}
//                 <section className="banner__area light-bg-s pt-40 pb-10">
//                     <div className="container custom-conatiner">
//                         <div className="row">
//                             <div className="col-xl-4 col-lg-4 col-md-12">
//                                 <div className="banner__item p-relative w-img mb-30">
//                                     <div className="banner__img b-radius-2">
//                                         <a href="product-details.html"><img src="assets/img/banner/banners E.jpg" alt=""/></a>
//                                     </div>
//                                     <div className="banner__content banner__content-2">
//                                         <h6><a href="product-details.html">Microsoft <br/> Surface Laptop 14”</a></h6>
//                                         <p className="sm-p">Up To -30%</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-xl-4 col-lg-4 col-md-12">
//                                 <div className="banner__item p-relative mb-30 w-img">
//                                     <div className="banner__img b-radius-2">
//                                         <a href="product-details.html"><img src="assets/img/banner/banners F.jpg" alt=""/></a>
//                                     </div>
//                                     <div className="banner__content banner__content-2">
//                                         <h6><a href="product-details.html">Cameras <br/>Best Sport Edition </a></h6>
//                                         <p className="sm-p">Up To -20%</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-xl-4 col-lg-4 col-md-12">
//                                 <div className="banner__item p-relative mb-30 w-img">
//                                     <div className="banner__img b-radius-2">
//                                         <a href="product-details.html"><img src="assets/img/banner/banners G.jpg" alt=""/></a>
//                                     </div>
//                                     <div className="banner__content banner__content-2">
//                                         <h6><a href="product-details.html">Sneaker <br/>Nike Air Max 90 </a></h6>
//                                         <p className="sm-p">Up To -60%</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//                 {/* banner__area-end */}
//             </React.Fragment>
//         );
//     }
// }
