import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { BeatLoader } from "react-spinners";

import Header from "../components/header-component";
import Footer from "../components/footer";

export default function about() {
  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {/* <!-- about-area-start --> */}
      <div
        className="about-area pt-80 pb-80"
        data-background="assets/img/bg/about-bg.png"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-content">
                <span>ABOUT OUR ONLINE STORE</span>
                <h4>Hello,</h4>
                <h5 className="banner-t mb-30">
                  Online for over 25 years, in store for over 55 years
                </h5>
                <p style={{ textAlign: "justify" }}>
                  Woodall Refrigeration Ltd is a family business based in NW
                  London and has supplied leading-brand kitchen appliances to
                  locals and beyond since 1967. Our special attention to
                  customer care and commitment to service has been passed down
                  from parents to children and now grand-children and the repeat
                  orders we enjoy from our regular customers is definite
                  validation of our philosophy that great customer service
                  should be at the forefront of our business. In 1997 we began
                  trading online with the tag line "They say online shopping is
                  the way of the future - Welcome to Tomorrow" and that was not
                  far off the mark as the internet propelled us in front of a
                  whole range of new customers who had discovered a new way of
                  doing business.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Although the main focus of our business has always (and still
                  is on request) been to the retail customers we are now
                  recognised by many house builders, architects, interior
                  designers and any business as the go to place for unrivalled
                  access to the sort of trade deals not generally available
                  elsewhere. Our long term relationship with the manufacturers
                  also allows us to pass on very attractive tiered pricing to
                  builders.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We have invested heavily in staff, technology and
                  infrastructure and with accredited training from all our
                  manufacturers, our staff are experts in the appliances they
                  sell. You can be sure that any kitchen appliance you buy from
                  us will carry our firm commitment to the very best in
                  after-sales service from a company you can trust.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-image w-img">
                <img src="../assets/img/logo/logo2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about-area-end --> */}

      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
