import React, { useState, useEffect } from "react";
import { getGiftSet } from "../services/apiServices";
import { Link } from "react-router-dom";
import { colors } from "@mui/material";

export default function PromotionComponent() {
  const [promoState, setPromoState] = useState([]);

  const promotionData = async () => {
    getGiftSet()
      .then((res) => {
        setPromoState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    promotionData();
  }, []);

  return (
    <React.Fragment>
      {promoState.length > 0 ? (
        <section className="trending-product-area light-bg-s pt-25 pb-15">
          <div className="container custom-conatiner">
            <div className="row">
              <div className="col-xl-12">
                <div className="section__head d-flex justify-content-between mb-30">
                  <div className="section__title section__title-2">
                    <h5 className="st-titile">Special Promotions</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4">
                <div className="row">
                  <div className="col-12">
                    <div className="banner__item p-relative w-img mb-30">
                      <div className="banner__img b-radius-2">
                        <a>
                          <img
                            src="assets/img/promotion/promotion.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="banner__content banner__content-2">
                        <h6
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            textDecoration: "none",
                          }}
                        >
                          All the little help
                        </h6>
                        <p style={{ fontSize: "16px", color: "black" }}>
                          Manufacture backed <br /> fantastic promotions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="swiper-container slider__active pb-30">
                  <div className="slider-wrapper swiper-wrapper">
                    {promoState.map((el) => {
                      return (
                        <Link
                          className="single-slider swiper-slide b-radius-2 slider-height-p d-flex align-items-center"
                          to={"/promotions/" + el.promotionId}
                          style={{
                            backgroundImage: `url(${el.imgUrl})`,
                          }}
                        >
                          {/* <div
                            key={el.promotionId}
                            // className="single-slider swiper-slide b-radius-2 slider-height-p d-flex align-items-center"
                            // data-background={el.imgUrl}
                            style={{
                              backgroundImage: `url(${el.imgUrl})`,
                            }}
                          > */}
                          {/* <div className="slider-content slider-content-2"> */}
                          {/* <h2
                                  data-animation="fadeInLeft"
                                  data-delay="1.7s"
                                  className="pt-15 slider-title pb-5"
                                >
                                  {el.name}
                                </h2> */}
                          {/* <p
                                  className="pr-20 slider_text"
                                  data-animation="fadeInLeft"
                                  data-delay="1.9s"
                                >
                                  {el.description} <br />
                                  Valid Till: {el.validTill}
                                </p> */}
                          {/* <div
                                  className="slider-bottom-btn mt-65"
                                  style={{ marginTop: "130px" }}
                                >
                                  <Link
                                    to={"/promotions/" + el.promotionId}
                                    className="st-btn-border b-radius-2"
                                    data-animation="fadeInUp"
                                    data-delay="1.15s"
                                  >
                                    More
                                  </Link>
                                </div> */}
                          {/* </div> */}
                          {/* </div> */}
                        </Link>
                      );
                    })}

                    <div className="main-slider-paginations"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
