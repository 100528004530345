import swal from "sweetalert2";
import { addToCartPOST } from "../services/apiServices";

//Add item to cart
function addToCart(productId, modelId, quantity, price) {
  const user = JSON.parse(localStorage.getItem("user"));

  addToCartPOST(user.customerId, productId, modelId, quantity, price, 1)
    .then((res) => {
      swal.fire({
        position: "top",
        icon: "success",
        title: "Success",
        text: "A new item has been added to Shopping Cart!",
        showConfirmButton: false,
        timer: 1500,
      });
    })
    .catch((error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(resMessage);
    });
  return null;
}

const Cart = {
  addToCart,
};

export default Cart;
