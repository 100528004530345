import { get } from "jquery";
import create from "zustand";
import { getShoppingCartData } from "../services/apiServices";

const useCartStore = create((set) => ({
  cartData: [],
  cartloading: false,
  updateCart: async () => {
    set({ cartloading: false });
    const response = await getShoppingCartData();
    set({ cartData: response.data, cartloading: true });
  },
}));

export default useCartStore;
