import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

import "../resources/css/login-style.css";
import "../resources/css/login.css";
import swal from "sweetalert2";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const sku = queryParameters.get("sku");

  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("TF");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isactive, setIsActive] = useState(false);

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password, type).then(
        (res) => {
          setIsActive(true);
          if (res.username === undefined) {
            if (sku == null) {
              navigate("/home");
            } else {
              let itemUrl = "/product/" + sku;
              navigate(itemUrl);
            }

            window.location.reload(true);
          } else {
            swal.fire({
              position: "top",
              icon: "error",
              title: "Error",
              text: res.username,
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="Main-container">
        <div
          className="container_1"
          style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
          <div className="wrap-login">
            <div className="login-pic">
              <img src="assets/img/logo/logo2.png" alt="IMG" />
            </div>
            <Form className="login-form" onSubmit={handleLogin} ref={form}>
              <span className="login-form-title_" style={{ color: "#666" }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "inherit",
                    margin: "inherit",
                  }}
                >
                  Woodalls B2B Trade Portal
                </p>
              </span>
              <span
                className="login-title"
                style={{ color: "#666", textAlign: "center" }}
              >
                This portal is accessible to authorised trade customers with a
                Woodalls trade account only.
              </span>
              <span className="login-title" style={{ textAlign: "center" }}>
                To login, enter login details below
              </span>
              <div className="wrap-input">
                <input
                  type="text"
                  className="input"
                  name="username"
                  placeholder="E-mail"
                  required
                  value={username}
                  onChange={onChangeUsername}
                />
                <span className="focus-input"></span>
                <span className="symbol-input">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
              </div>
              <div className="wrap-input">
                <input
                  type="password"
                  className="input"
                  name="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={onChangePassword}
                />
                <span className="focus-input"></span>
                <span className="symbol-input">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
              </div>

              <div className="wrap-input">
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "10px",
                    lineHeight: "15px",
                  }}
                >
                  By logging in, I agree to the T&Cs and to Woodalls's
                  collection, use and storage of my personal information as set
                  out in the {""}
                  <a
                    href="https://www.we-sell-it.co.uk/terms"
                    style={{
                      color: "#2c2e72",
                      fontWeight: "bold",
                      fontSize: "10px",
                    }}
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>
                  .
                </p>
              </div>

              {/* <div className="remember-password">
                <input
                  type="checkbox"
                  id="remember-password"
                  name="remember-password"
                  value="rpassword"
                />
                <label>Remember Password</label>
              </div> */}
              <div className="login-form-btn-container">
                <button
                  className="login-form-btn"
                  style={{ marginTop: "-20px" }}
                  disabled={loading}
                >
                  Login &nbsp;
                  {loading && (
                    <span className="spinner-border spiner-border-sm align-right"></span>
                  )}
                </button>
              </div>
              <div className="login-form-btn-container">
                <a
                  href="/signup"
                  className="login-form-btn"
                  style={{ color: "white" }}
                >
                  Sign Up{" "}
                </a>
              </div>
              <div className="text-center p-t-1">
                {/* <span className="txt1">Forgot</span> */}
                <a href="/forgot-password" className="txt2">
                  Forgot Password /
                </a>{" "}
                <a href="/unlock-account" className="txt2">
                  Unlock Account
                </a>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// class Login1 extends Component {
//     constructor( props ) {
//         super(props);
//         this.handleLogin = this.handleLogin.bind(this);
//         this.onChangeUsername = this.onChangeUsername.bind(this);
//         this.onChangePassword = this.onChangePassword.bind(this);

//         this.state = {
//             username: "",
//             password: "",
//             type: "TF",
//             loading: false,
//             message: ""
//         };
//     }

//     onChangeUsername(e) {
//         this.setState({
//             username: e.target.value
//         });
//     }

//     onChangePassword(e) {
//         this.setState({
//             password: e.target.value
//         });
//     }

//     handleLogin(e) {
//         e.preventDefault();

//         this.setState({
//             message: "",
//             loading: true
//         });

//         if (this.checkBtn.context._errors.length === 0) {
//             AuthService.login(this.state.username, this.state.password, this.state.type).then (
//                 () => {
//                     this.props.navigate("/home");
//                     window.location.reload();
//                 },
//                 error => {
//                     const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

//                     this.setState({
//                         loading: false,
//                         message: resMessage
//                     });
//                 }
//             );
//         } else {
//             this.setState({
//                 loading: false
//             });
//         }
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="Main-container">
//                     <div className="login-container">
//                         <div className="wrap-login">
//                             <div className="login-pic">
//                                 <img src="assets/img/logo/logo.png" alt="IMG"/>
//                             </div>
//                             <Form className="login-form"
//                                 onSubmit={this.handleLogin}
//                                 ref={c => {
//                                     this.form = c;
//                                 }}
//                             >
//                                 <span className="login-form-title_">Hello,</span>
//                                 <span className="login-title">I'm excited to have you back.</span>
//                                 <div className="wrap-input">
//                                     <input
//                                         type="text"
//                                         className="input"
//                                         name="username"
//                                         placeholder="Email"
//                                         required
//                                         value={this.state.username}
//                                         onChange={this.onChangeUsername}
//                                     />
//                                     <span className="focus-input"></span>
//                                     <span className="symbol-input">
//                                         <i className="fa fa-envelope" aria-hidden="true"></i>
//                                     </span>
//                                 </div>
//                                 <div className="wrap-input">
//                                     <input
//                                         type="password"
//                                         className="input"
//                                         name="password"
//                                         placeholder="Password"
//                                         required
//                                         value={this.state.password}
//                                         onChange={this.onChangePassword}
//                                     />
//                                     <span className="focus-input"></span>
//                                     <span className="symbol-input">
//                                         <i className="fa fa-lock" aria-hidden="true"></i>
//                                     </span>
//                                 </div>
//                                 <div className="remember-password">
//                                     <input type="checkbox" id="remember-password" name="remember-password" value="rpassword"/>
//                                     <label>Remember Password</label>
//                                 </div>
//                                 <div className="login-form-btn-container">
//                                     <button className="login-form-btn" disabled={this.state.loading}>
//                                         Login &nbsp;
//                                         {this.state.loading && (
//                                             <span className='spinner-border spiner-border-sm align-right'></span>
//                                         )}
//                                     </button>

//                                 </div>
//                                 <div className="login-form-btn-container">
//                                     <a href="Sing-up.html" className="login-form-btn">Sing-up </a>
//                                 </div>
//                                 <div className="text-center p-t-1">
//                                     <span className="txt1">Forgot</span>
//                                     <a href="#" className="txt2"> Username / Password ?</a>
//                                 </div>

//                                 {this.state.message && (
//                                     <div className='form-group'>
//                                         <div className='alert alert-danger' role="alert">
//                                             {this.state.message}
//                                         </div>
//                                     </div>
//                                 )}
//                                 <CheckButton
//                                     style={{ display: "none" }}
//                                     ref={c => {
//                                         this.checkBtn = c;
//                                     }}
//                                 />
//                             </Form>
//                         </div>
//                     </div>
//                 </div>
//             </React.Fragment>
//         );
//     }
// }

// const withRouter = WrappedComponent => props => {
//     const params = useParams();
//     const navigate = useNavigate();

//     return (
//         <WrappedComponent
//             {...props}
//             params={params}
//             navigate={navigate}
//         />
//     );
// };

export default Login;
