import axios from "axios";
const token = JSON.parse(localStorage.getItem("headers"));
const user = JSON.parse(localStorage.getItem("user"));

const axiosInstance = axios.create({
  baseURL: "https://erp.woodalls.co.uk/",
  // baseURL: "http://52.56.217.109/",
  // baseURL: "http://localhost:8181/WoodallsERP/",
});

//set headers
axiosInstance.interceptors.request.use((config) => {
  if (token) config.headers = token;
  config.headers["Content-Type"] = "application/json";
  return config;
});

export default axiosInstance;
