import React, { Component, Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AuthService from "./services/auth.service";

import PrivateRoute from "./services/private-route";
import PageNotFound from "./pages/pageNotFound";
import Login from "./pages/login";
import SignUp from "./pages/signUp";
import Home from "./pages/home";
import SingleProduct from "./pages/single-product";
import ShoppingCart from "./pages/shopping-cart";
import CheckOut from "./pages/checkOut";
import MyAccount from "./pages/myAccount";
import Order from "./pages/orderDetails";
import Delivery from "./pages/deliveryDetails";
import CategoryProducts from "./pages/category-products";
import ContactUs from "./pages/contactUs";
import About from "./pages/about";
import Terms from "./pages/terms";
import Brand from "./pages/brands";
import EmailVerification from "./pages/email-verification";
import ForgotPassword from "./pages/forgot-password";
import RequestUnlockAccount from "./pages/request-unlock-account";
import UnlockedAccount from "./pages/unlock-account-email-verification";
import Promotions from "./pages/promotions";

// const validEmailRegex = RegExp(
//   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
// );

// const validateForm = errors => {
//   let valid = true;
//   Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//   return valid;
// };

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: AuthService.getCurrentUser(),
      });
    }
  }

  logOut() {
    AuthService.logOut();
    this.setState({
      currentUser: null,
    });
  }

  render() {
    const { currentUser } = this.state;
    return (
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />

            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route
              exact
              path="/unlock-account"
              element={<RequestUnlockAccount />}
            />

            <Route
              exact
              path="/unlocked-account"
              element={<UnlockedAccount />}
            />

            <Route exact path="/verify-email" element={<EmailVerification />} />

            <Route exact path="/home" element={<PrivateRoute />}>
              <Route exact path="/home" element={<Home />} />
            </Route>

            <Route exact path="/product/:sku" element={<PrivateRoute />}>
              <Route exact path="/product/:sku" element={<SingleProduct />} />
            </Route>

            <Route exact path="/shoppingCart" element={<PrivateRoute />}>
              <Route exact path="/shoppingCart" element={<ShoppingCart />} />
            </Route>

            <Route exact path="/checkOut" element={<PrivateRoute />}>
              <Route exact path="/checkOut" element={<CheckOut />} />
            </Route>

            <Route exact path="/my-account" element={<PrivateRoute />}>
              <Route exact path="/my-account" element={<MyAccount />} />
            </Route>

            <Route exact path="/page-not-found" element={<PrivateRoute />}>
              <Route exact path="/page-not-found" element={<PageNotFound />} />
            </Route>

            <Route exact path="/order/:orderNo" element={<PrivateRoute />}>
              <Route exact path="/order/:orderNo" element={<Order />} />
            </Route>

            <Route exact path="contactUs" element={<ContactUs />} />

            <Route exact path="terms" element={<Terms />} />

            <Route exact path="/about" element={<About />} />

            <Route exact path="/brand/:id" element={<PrivateRoute />}>
              <Route exact path="/brand/:id" element={<Brand />} />
            </Route>

            <Route exact path="/delivery/:id" element={<PrivateRoute />}>
              <Route exact path="/delivery/:id" element={<Delivery />} />
            </Route>

            <Route exact path="/category-product" element={<PrivateRoute />}>
              <Route
                exact
                path="/category-product"
                element={<CategoryProducts />}
              />
            </Route>

            <Route exact path="/promotions/:id" element={<PrivateRoute />}>
              <Route exact path="/promotions/:id" element={<Promotions />} />
            </Route>

            {/* 👇️ only match this when no other routes match */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
