import React from "react";
import axios from "axios";
import UserService from "./user.service";

class AuthService {
  login = (username, password, type) => {
    return axios
      .post(UserService.api_Url + "api/app/signin", {
        username,
        password,
        type,
      })
      .then((res) => {
        if (res.headers.authorization) {
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem("headers", JSON.stringify(res.headers));
        }
        return res.data;
      });
  };

  unlock = (username, type) => {
    return axios
      .post(UserService.api_Url + "api/app/unlock-account-request", {
        username,
        type,
      })
      .then((res) => {
        return res.data;
      });
  };

  resetPassword = (username, type) => {
    return axios
      .post(UserService.api_Url + "api/app/tf-reset-password", {
        username,
        type,
      })
      .then((res) => {
        return res.data;
      });
  };

  logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("headers");
    window.location.href = "/";
  };

  getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
}

export default new AuthService();
