import React, { useState, useEffect } from "react";

import Header from "../components/header-component";
import Footer from "../components/footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { promotionDetailsGET } from "../services/apiServices";
import UserService from "../services/user.service";

export default function Promotions() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [promoDataState, setPromoDataState] = useState([]);
  const [promoListState, setPromoListState] = useState([]);

  //get product categories
  const getPromoData = async (promoId) => {
    promotionDetailsGET(promoId)
      .then((res) => {
        setPromoDataState(res.data);
        if (res.data.promotionId != 0) {
          setPromoListState(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      getPromoData(id);
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      <section className="breadcrumb__area box-plr-75">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Promotions
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      {promoDataState.promotionId != 0 ? (
        <section className="trending-product-area light-bg-s pt-25 pb-15">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 single-slider b-radius-2 slider-height-p d-flex align-items-center"> */}
              <a
                href={promoDataState.claimUrl}
                className="st-btn-border b-radius-2 col-xl-9 single-slider b-radius-2 slider-height-p d-flex align-items-center"
                style={{
                  backgroundImage: `url(${promoDataState.imgUrl})`,
                  // border: "1px solid #e9e9e9",
                  width: "none",
                }}
                target="_blank"
              ></a>
              {/* <div className="slider-content slider-content-2">
                 
                  {promoDataState.claimMethod == 2 ? (
                    <div
                      className="slider-bottom-btn mt-65"
                      style={{ marginTop: "130px" }}
                    >
                      <a
                        href={promoDataState.claimUrl}
                        className="st-btn-border b-radius-2"
                        data-animation="fadeInUp"
                        data-delay="1.15s"
                        target="_blank"
                      >
                        More
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
              {/* </div> */}
              <div className="col-xl-3">
                <div
                  style={{
                    minHeight: "312.38px",
                    border: "1px solid #d7d8db",
                    padding: "0 15px 0 15px",
                    borderRadius: "20px",
                    textAlign: "center",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {promoDataState.promoType == 2 ? (
                      <h2 style={{ fontSize: "50px" }}>Gift</h2>
                    ) : promoDataState.promoType == 1 ? (
                      <React.Fragment>
                        <h2 style={{ fontSize: "50px" }}>CashBack</h2>
                        <p style={{ fontSize: "30px" }}>
                          {promoDataState.minValue != 0 &&
                          promoDataState.minValue != promoDataState.maxValue
                            ? UserService.numberFormat(promoDataState.minValue)
                                .toString()
                                .slice(0, -3) +
                              " - " +
                              UserService.numberFormat(promoDataState.maxValue)
                                .toString()
                                .slice(0, -3)
                            : UserService.numberFormat(promoDataState.maxValue)
                                .toString()
                                .slice(0, -3)}
                        </p>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    <p style={{ fontSize: "15px" }}>
                      From {promoDataState.startDate} to{" "}
                      {promoDataState.validTill}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <br />
            {/* <div className="row" style={{ paddingBottom: "30px" }}> */}
            {/* <div className="section__head d-flex justify-content-between mb-30">
                <div className="section__title section__title-2">
                  <h5 className="st-titile">Promotions</h5>
                </div>
                <div className="button-wrap button-wrap-2"></div>
              </div> */}
            {promoListState.length > 0 &&
              promoListState.map((el, idx) => {
                let promoItem = el.promoItem;
                let parentItem = el.parentItems;
                return (
                  <React.Fragment>
                    <div
                      className="row"
                      key={idx}
                      style={{
                        marginBottom: "30px",
                        border: "1px solid #d7d8db",
                        borderRadius: "20px",
                        padding: "14px 0 0 0",
                      }}
                    >
                      <div className="col-xl-3">
                        <div className="row">
                          <div className="col-12">
                            <div className="banner__item p-relative w-img mb-30">
                              <div className="banner__img b-radius-2">
                                {promoItem.productId != 0 ? (
                                  <Link to={"/product/" + promoItem.sku}>
                                    <img
                                      src={promoItem.defaultImage}
                                      alt={promoItem.sku}
                                    />
                                  </Link>
                                ) : el.type == 1 ? (
                                  <div
                                    style={{
                                      opacity: "60%",
                                      fontSize: "24px",
                                      minHeight: "255px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "40px",
                                    }}
                                  >
                                    {UserService.numberFormat(promoItem.value)
                                      .toString()
                                      .slice(0, -3)}{" "}
                                  </div>
                                ) : el.type == 3 ? (
                                  <div
                                    style={{ opacity: "60%", fontSize: "24px" }}
                                  >
                                    {promoItem.value}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="banner__content_promo banner__content-2">
                                {promoItem.productId != 0 ? (
                                  <React.Fragment>
                                    <div
                                      class="promax"
                                      style={{ opacity: "60%" }}
                                    >
                                      <Link to={"/product/" + promoItem.sku}>
                                        <h6 style={{ color: "white" }}>
                                          {promoItem.sku}
                                        </h6>
                                      </Link>
                                    </div>

                                    <div class="pro" style={{ opacity: "60%" }}>
                                      {promoItem.productName}
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-9">
                        {/* <p style={{ color: "red" }}>
                          Free with the purchases of the following
                        </p> */}
                        <div className="row">
                          {parentItem.map((el) => {
                            return (
                              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                                <div className="product__item product__item-2 b-radius-2 mb-20">
                                  <div className="product__thumb fix">
                                    <div className="product-image w-img">
                                      {el.type == 3 ? ( //product
                                        <Link to={"/product/" + el.sku}>
                                          <img
                                            src={el.defaultImage}
                                            alt={el.sku}
                                          />
                                        </Link>
                                      ) : el.type == 1 ? ( //brand
                                        <Link to={"/brand/" + el.brandId}>
                                          <img
                                            src={el.defaultImage}
                                            alt={el.brand}
                                          />
                                        </Link>
                                      ) : el.type == 2 ? ( //category
                                        <Link
                                          to={
                                            "/category-product?category=/" +
                                            el.categoryId +
                                            "&brand=[0]"
                                          }
                                        >
                                          <img
                                            src={el.defaultImage}
                                            alt={el.mainCategory}
                                          />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="product__content product__content-2">
                                    {el.type == 3 ? (
                                      <h6 style={{ textAlign: "center" }}>
                                        <Link to={"/product/" + el.sku}>
                                          {el.sku} <br />
                                          {el.mainCategory}
                                        </Link>
                                      </h6>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
          {/* </div> */}
        </section>
      ) : (
        ""
      )}

      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
