import React, { useState, useEffect, number } from "react";
import UserService from "../services/user.service";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "../resources/css/shop.css";

import Header from "../components/header-component";
import Footer from "../components/footer";
import { RiseLoader } from "react-spinners";
import ReactPaginate from "react-paginate";

import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { loadScript } from "../utility/loadScriptUtil";
import $ from "jquery";
import {
  getBrands,
  getCategories,
  getChildCategories,
  getChildCategoriesByCategoryBrands,
  getProductsByCatOrBrands,
} from "../services/apiServices";
import cart from "../common/cart";
import useCartStore from "../common/globalStore";

function valuetext(value) {
  return `${value}°C`;
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function categoryProducts() {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [pageStart, setPageStart] = useState(1);
  const [selectedPageLength, setSelectedPageLength] = useState(30);
  const [startRecord, setStartRecords] = useState(1);
  const [pageRange, setPageRange] = useState(1);
  const [productList, setProductList] = useState([]);
  const [displayRecords, setDisplayRecords] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState(0);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState([]);
  let orderBy = 0;

  const getCartData = useCartStore((state) => state.updateCart);

  const [value, setValue] = useState([0, 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function changeOrderBy(el) {
    orderBy = Number(el.target.value);
    getProduct(Number(searchParams.get("category")), selectedPageLength, true);
  }

  function changeDisplayLength(e) {
    setSelectedPageLength(e);
    getProduct(Number(searchParams.get("category")), e, true);
  }

  //get products by category or brands
  const products = async (categoryId, displayStart, brands, pageLength) => {
    getProductsByCatOrBrands(
      categoryId,
      displayStart,
      pageLength,
      brands,
      orderBy
    )
      .then((res) => {
        let data = res.data;
        let range = data.totalRecords / pageLength;
        console.log(range.toFixed(0));

        setDisplayRecords(data.totalDisplayRecords);
        setTotalRecords(data.totalRecords);
        setProductList(data.products);
        setStartRecords((displayStart - 1) * pageLength + 1);
        setPageRange(Number(range.toFixed(0)));
        categoryData(categoryId);
        setPaginationInfo(data.info);
        setLoading(true);
        setProductLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get products
  function getProduct(catId, pageLength, pageReset) {
    setProductLoading(false);
    const brands = [];

    //get all slected value in variant drop down
    const allBrands = Array.from(
      document.querySelectorAll("input.selectedBrands")
    );

    //looping selected value and create integer array
    for (var i = 0, l = allBrands.length; i < l; i++) {
      if (allBrands[i].checked) {
        brands.push(Number(allBrands[i].value));
      }
    }
    if (pageReset) {
      setPageStart(1);
      products(catId, 1, brands, pageLength);
    } else {
      products(catId, pageStart, brands, pageLength);
    }
  }

  //get product brands
  const brandList = async () => {
    getBrands()
      .then((res) => {
        setBrands(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get product categories
  const categoryData = async (catId) => {
    getChildCategories(catId)
      .then((res) => {
        setBreadCrumb(res.data.breadCrumb);
        setSubCat(res.data.levelone);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //toggle product categories
  function catToggler(e) {
    if (e.target.parentNode.querySelector(".nested") != null) {
      e.target.parentNode.querySelector(".nested").classList.toggle("active");
      e.target.classList.toggle("check-box");
    }
  }

  function handlePageChange(event) {
    let pageNumber = event.selected + 1;
    console.log(`active page is ${pageNumber}`);
    setPageStart(pageNumber);

    setProductLoading(false);
    const brands = [];

    //get all slected value in variant drop down
    const allBrands = Array.from(
      document.querySelectorAll("input.selectedBrands")
    );

    //looping selected value and create integer array
    for (var i = 0, l = allBrands.length; i < l; i++) {
      if (allBrands[i].checked) {
        brands.push(Number(allBrands[i].value));
      }
    }

    products(
      Number(searchParams.get("category")),
      pageNumber,
      brands,
      selectedPageLength
    );
  }

  //add to cart function
  function fnAddToCart(productId, modelId, qty, price) {
    cart.addToCart(productId, modelId, qty, price);
    setTimeout(
      function () {
        //Start the timer
        getCartData();
      }.bind(this),
      1000
    );
  }

  function selectBrand(brandId) {
    //get all slected value in variant drop down
    const allBrands = Array.from(
      document.querySelectorAll("input.selectedBrands")
    );

    //looping selected value and create integer array
    for (var i = 0, l = allBrands.length; i < l; i++) {
      if (allBrands[i].value == brandId) {
        allBrands[i].checked = true;
      }
    }
  }

  useEffect(() => {
    const brands = [];
    if (searchParams.get("brand").slice(1, -1) != 0) {
      setBrandId(Number(searchParams.get("brand").slice(1, -1)));
      brands.push(searchParams.get("brand").slice(1, -1));
    }

    (async () => {
      products(
        Number(searchParams.get("category")),
        pageStart,
        brands,
        selectedPageLength
      );

      categoryData(Number(searchParams.get("category")));
      brandList();

      await delay(2000);
      selectBrand(searchParams.get("brand").slice(1, -1));
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {/* breadcrumb__area-start */}
      <section className="breadcrumb__area box-plr-75">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>

                    <li className="breadcrumb-item">Products</li>
                    {breadCrumb.length > 0 &&
                      breadCrumb.map((el, idx) => {
                        return (
                          <li
                            key={idx}
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                getProduct(el.id, selectedPageLength, true);
                              }}
                            >
                              {el.name}
                            </a>
                          </li>
                        );
                      })}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb__area-end */}

      {!loading ? (
        <div className="spinner">
          <RiseLoader color="#263c97" size={20} />
        </div>
      ) : (
        <main>
          <div className="shop-area mb-20">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="product-widget mb-30">
                    <h5 className="pt-title">Product categories</h5>
                    <div className="widget-category-list mt-20">
                      <form action="#">
                        <ul className="myUL">
                          {subCat.length > 0 &&
                            subCat.map((el) => {
                              return (
                                <li
                                  key={el.categoryId}
                                  onClick={(e) => catToggler(e)}
                                  style={{
                                    fontWeight: "600",
                                    cursor: "pointer",
                                  }}
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      // navigate({
                                      //   pathname: "/category-product",
                                      //   search:
                                      //     "?category=" +
                                      //     el.categoryId +
                                      //     "&brand=0",
                                      // });
                                      // window.location.reload(true);
                                      getProduct(
                                        el.categoryId,
                                        selectedPageLength,
                                        true
                                      );
                                    }}
                                  >
                                    {el.categoryName}
                                  </a>
                                </li>
                              );
                            })}
                          <li></li>
                        </ul>
                      </form>
                    </div>
                  </div>
                  {/* <div className="product-widget mb-30">
                    <h5 className="pt-title">Filter By Price</h5>

                    <Slider
                      min={0}
                      max={10000}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      color="secondary"
                    /> */}
                  {/* <div className="price__slider mt-30">
                      <div
                        id="slider-range"
                        className="ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                      >
                        <div
                          className="ui-slider-range ui-corner-all ui-widget-header"
                          style={{ left: "0%", width: "100%" }}
                        ></div>
                        <span
                          tabIndex={0}
                          className="ui-slider-handle ui-corner-all ui-state-default"
                          style={{ left: "0%" }}
                        ></span>
                        <span
                          tabIndex={0}
                          className="ui-slider-handle ui-corner-all ui-state-default"
                          style={{ left: "100%" }}
                        ></span>
                      </div>
                      <div>
                        <form action="#" className="s-form mt-20">
                          <input type="text" id="amount" readOnly="" />
                          <button type="submit" className="tp-btn-square-lg">
                            Filter
                          </button>
                        </form>
                      </div>
                    </div> */}
                  {/* </div> */}

                  <div className="product-widget mb-30">
                    <h5 className="pt-title">Brands</h5>
                    <div className="product__sm mt-20">
                      <form>
                        <ul>
                          {brands.length > 0 &&
                            brands.map((el) => {
                              return (
                                <li key={el.brandId}>
                                  <input
                                    className="selectedBrands"
                                    type="checkbox"
                                    value={el.brandId}
                                    style={{ cursor: "pointer" }}
                                    onChange={(e) =>
                                      getProduct(
                                        searchParams.get("category"),
                                        selectedPageLength,
                                        true
                                      )
                                    }
                                  />
                                  <label
                                    style={{
                                      float: "revert",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {el.name}
                                  </label>
                                </li>
                              );
                            })}

                          <br />
                        </ul>
                      </form>
                    </div>
                  </div>

                  <div className="product-widget mb-30">
                    <h5 className="pt-title">Special Offers</h5>
                    <div className="product__sm mt-20">
                      <ul>
                        {productList.map((el) => {
                          if (el.specialPrice != 0) {
                            return (
                              <li
                                key={el.productId}
                                className="product__sm-item d-flex align-items-center"
                              >
                                <div className="product__sm-thumb mr-20">
                                  <Link to={"/product/" + el.sku}>
                                    <img
                                      src={el.defaultImage}
                                      alt="product"
                                      style={{
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Link>
                                </div>
                                <div className="product__sm-content">
                                  <h5 className="product__sm-title">
                                    <Link to={"/product/" + el.sku}>
                                      {el.productName}
                                    </Link>
                                  </h5>
                                  <div className="product__sm-price">
                                    {el.modelId === 0 && el.specialPrice > 0 ? (
                                      <React.Fragment>
                                        <span style={{ color: "red" }}>
                                          <s>
                                            {UserService.numberFormat(
                                              el.orginalPrice
                                            )}
                                          </s>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span>
                                          {UserService.numberFormat(
                                            el.specialPrice
                                          )}
                                        </span>
                                        <span className="vat">(exc VAT)</span>
                                      </React.Fragment>
                                    ) : el.modelId === 0 &&
                                      el.orginalPrice > 0 ? (
                                      <React.Fragment>
                                        <span>
                                          {UserService.numberFormat(
                                            el.orginalPrice
                                          )}
                                        </span>
                                        <span className="vat"> (exc VAT)</span>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="product-lists-top">
                    <div className="product__filter-wrap">
                      <div className="row align-items-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                          <div className="product__filter d-sm-flex align-items-center">
                            <div className="product__col">
                              <ul
                                className="nav nav-tabs"
                                id="myTab"
                                role="tablist"
                              >
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link active"
                                    id="FourCol-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#FourCol"
                                    type="button"
                                    role="tab"
                                    aria-controls="FourCol"
                                    aria-selected="true"
                                  >
                                    <i className="fal fa-th"></i>
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link"
                                    id="FiveCol-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#FiveCol"
                                    type="button"
                                    role="tab"
                                    aria-controls="FiveCol"
                                    aria-selected="false"
                                  >
                                    <i className="fal fa-list"></i>
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="product__result pl-60">
                              <p>{paginationInfo}&nbsp; results</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                          <div className="product__filter-right d-flex align-items-center justify-content-md-end">
                            <div className="product__sorting product__show-no">
                              <select
                                onChange={(e) => {
                                  changeDisplayLength(Number(e.target.value));
                                }}
                              >
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                            <div className="product__sorting product__show-position ml-20">
                              <select
                                onChange={(e) => {
                                  changeOrderBy(e);
                                }}
                              >
                                <option value={0}>Best matches</option>
                                <option value={2}>Price (High to Low)</option>
                                <option value={1}>Price (Low to High)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content" id="productGridTabContent">
                    <div
                      className="tab-pane fade  show active"
                      id="FourCol"
                      role="tabpanel"
                      aria-labelledby="FourCol-tab"
                    >
                      {!productLoading ? (
                        <div className="spinner">
                          <RiseLoader color="#263c97" size={20} />
                        </div>
                      ) : (
                        <div className="tp-wrapper">
                          <div className="row g-0">
                            {productList.map((el) => {
                              let price =
                                el.variantCount === 0
                                  ? el.specialPrice === 0
                                    ? el.orginalPrice
                                    : el.specialPrice
                                  : 0;
                              return (
                                <div
                                  key={el.sku}
                                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                >
                                  <div className="product__item product__item-d">
                                    <div className="product__thumb fix">
                                      <div className="product-image w-img">
                                        <Link to={"/product/" + el.sku}>
                                          <img
                                            src={el.defaultImage}
                                            alt="product"
                                            style={{
                                              height: "165px",
                                              width: "165px",
                                              objectFit: "contain",
                                            }}
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="product__content-3">
                                      <h6>
                                        <Link to={"/product/" + el.sku}>
                                          {el.productName}
                                        </Link>
                                        {el.tempStop ? (
                                          <React.Fragment>
                                            <br />
                                            <span className="status_font">
                                              Temporary not available
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </h6>

                                      <div className="price mb-10">
                                        {el.modelId === 0 &&
                                        el.specialPrice > 0 ? (
                                          <React.Fragment>
                                            <span style={{ color: "red" }}>
                                              <s>
                                                {UserService.numberFormat(
                                                  el.orginalPrice
                                                )}
                                              </s>
                                            </span>
                                            &nbsp;&nbsp;
                                            <span>
                                              {UserService.numberFormat(
                                                el.specialPrice
                                              )}
                                            </span>
                                            <span className="vat">
                                              (exc VAT)
                                            </span>
                                          </React.Fragment>
                                        ) : el.modelId === 0 &&
                                          el.orginalPrice > 0 ? (
                                          <React.Fragment>
                                            <span>
                                              {UserService.numberFormat(
                                                el.orginalPrice
                                              )}
                                            </span>
                                            <span className="vat">
                                              (exc VAT)
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div className="product__add-cart-s text-center">
                                      {el.variantCount > 0 ? (
                                        <a
                                          type="button"
                                          className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                                          href={"/product/" + el.sku}
                                        >
                                          <i className="fa fa-eye"></i> &nbsp;
                                          View Variant
                                        </a>
                                      ) : el.tempStop ? (
                                        ""
                                      ) : (
                                        <button
                                          type="button"
                                          className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                                          onClick={() =>
                                            fnAddToCart(
                                              el.productId,
                                              el.modelId,
                                              1,
                                              price
                                            )
                                          }
                                        >
                                          <i className="w-icon-cart"></i> Add to
                                          Cart
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="FiveCol"
                      role="tabpanel"
                      aria-labelledby="FiveCol-tab"
                    >
                      <div className="tp-wrapper-2">
                        {productList.map((el) => {
                          let price =
                            el.variantCount === 0
                              ? el.specialPrice === 0
                                ? el.orginalPrice
                                : el.specialPrice
                              : 0;
                          return (
                            <div key={el.sku} className="single-item-pd">
                              <div className="row align-items-center">
                                <div className="col-xl-9">
                                  <div className="single-features-item single-features-item-df b-radius mb-20">
                                    <div className="row  g-0 align-items-center">
                                      <div className="col-md-4">
                                        <div className="features-thum">
                                          <div className="features-product-image w-img">
                                            <Link to={"/product/" + el.sku}>
                                              <img
                                                src={el.defaultImage}
                                                alt="product"
                                                style={{
                                                  height: "165px",
                                                  width: "165px",
                                                  objectFit: "contain",
                                                }}
                                              />
                                            </Link>
                                          </div>

                                          <div className="product-action">
                                            <Link
                                              to={"/product/" + el.sku}
                                              className="icon-box icon-box-1"
                                            >
                                              <i className="fal fa-eye"></i>
                                              <i className="fal fa-eye"></i>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <div className="product__content product__content-d">
                                          <h6>
                                            <Link
                                              to={"/product/" + el.sku}
                                              style={{ whiteSpace: "pre-wrap" }}
                                            >
                                              {el.productFullName}
                                            </Link>
                                          </h6>
                                          {el.tempStop == true ? (
                                            <span className="status_font">
                                              Temporary not available
                                            </span>
                                          ) : (
                                            ""
                                          )}

                                          <div className="features-des">
                                            <ul>
                                              <li>
                                                <a>
                                                  <i className="fas fa-circle"></i>
                                                  SKU: {el.sku}
                                                </a>
                                              </li>
                                              <li>
                                                <a>
                                                  <i className="fas fa-circle"></i>
                                                  EAN:
                                                  {el.ean != ""
                                                    ? el.ean
                                                    : "N/A"}
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-3">
                                  <div className="product-stock mb-15">
                                    {el.modelId === 0 && el.specialPrice > 0 ? (
                                      <React.Fragment>
                                        <span style={{ color: "red" }}>
                                          <s>
                                            {UserService.numberFormat(
                                              el.orginalPrice
                                            )}
                                          </s>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span>
                                          {UserService.numberFormat(
                                            el.specialPrice
                                          )}
                                        </span>
                                        <span className="vat">(exc VAT)</span>
                                      </React.Fragment>
                                    ) : el.modelId === 0 &&
                                      el.orginalPrice > 0 ? (
                                      <React.Fragment>
                                        <span>
                                          {UserService.numberFormat(
                                            el.orginalPrice
                                          )}
                                        </span>
                                        <span className="vat"> (exc VAT)</span>
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="stock-btn ">
                                    {el.tempStop == false ? (
                                      <button
                                        type="button"
                                        className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                                        onClick={() =>
                                          fnAddToCart(
                                            el.productId,
                                            el.modelId,
                                            1,
                                            price
                                          )
                                        }
                                      >
                                        Add to Cart
                                      </button>
                                    ) : (
                                      ""
                                    )}

                                    {/* <button
                                      type="button"
                                      className="wc-checkout d-flex align-items-center justify-content-center w-100"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      Quick View
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div>
                    <nav aria-label="Page navigation comments" className="mt-4">
                      <ReactPaginate
                        previousLabel="previous"
                        nextLabel="next"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageRange}
                        pageRangeDisplayed={4}
                        marginPagesDisplayed={2}
                        onPageChange={(e) => handlePageChange(e)}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        // eslint-disable-next-line no-unused-vars
                      />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}

      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
