import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import AuthService from "../services/auth.service";

import "../resources/css/tab_setting.css";

import {
  allSalesInvoiceListPOST,
  deliveryListPOST,
  getSalesDataPOST,
  getTpCustomerDcoHeaderData,
  paymentListPOST,
  saveDocHeaderData,
  submitChangePassword,
  userDataGET,
} from "../services/apiServices";

import Header from "../components/header-component";
import Footer from "../components/footer";
import ReactPaginate from "react-paginate";

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { useRef } from "react";
import $ from "jquery";

export default function myAccount() {
  const form = useRef();
  const checkBtn = useRef();

  const [salesListState, setSalesListState] = useState(null);
  const [quotListState, setQuotListState] = useState(null);
  const [invoicesState, setInvoicesState] = useState(null);
  const [paymentsState, setPaymentsState] = useState(null);
  const [deliveriesState, setDeliveriesState] = useState(null);
  const [userState, setUserState] = useState(null);
  const [selectedAddState, setSelectedAddState] = useState(null);
  const [countryState, setCountryState] = useState(null);
  const [baseCountryState, setBaseCountryState] = useState(null);
  const [cartloading, setCartLoading] = useState(false);
  const [selectedPageLength, setSelectedPageLength] = useState(20);

  //sales
  const [SLFilter, setSLFilter] = useState("");
  const [SLPageRange, setSLPageRange] = useState(1);
  const [SLPaginationInfo, setSLPaginationInfo] = useState([]);
  const [QuoFilter, setQuoFilter] = useState("");
  const [QuoPageRange, setQuoSLPageRange] = useState(1);
  const [QuoPaginationInfo, setQuoSLPaginationInfo] = useState([]);

  //invoice
  const [InvFilter, setInvFilter] = useState("");
  const [InvPageRange, setInvPageRange] = useState(1);
  const [InvPaginationInfo, setInvPaginationInfo] = useState([]);

  //Payment
  const [PayFilter, setPayFilter] = useState("");
  const [PayPageRange, setPayPageRange] = useState(1);
  const [PayPaginationInfo, setPayPaginationInfo] = useState([]);

  //Delivery
  const [DelFilter, setDelFilter] = useState("");
  const [DelPageRange, setDelPageRange] = useState(1);
  const [DelPaginationInfo, setDelPaginationInfo] = useState([]);

  //reset password
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState([]);
  const [headerFormErrors, setHeaderFormErrors] = useState([]);

  //header details
  const [coHeader, setCoHeader] = useState(null);
  const [coRegNo, setCoRegNo] = useState("");
  const [coVatNo, setCoVatNo] = useState("");
  const [coEmail, setCoEmail] = useState("");
  const [coContact, setCoContact] = useState("");
  const [coFaxNo, setCoFaxNo] = useState("");

  //get sales data
  const getSalesData = async (
    orderType,
    reference,
    from,
    till,
    displayStart,
    displayLength
  ) => {
    getSalesDataPOST(
      orderType,
      reference,
      from,
      till,
      displayStart,
      displayLength
    )
      .then((res) => {
        const row = res.data;
        let range = row.totalRecords / displayLength;
        let round = range.toFixed(0);

        if (round < range) {
          round = Number(round) + 1;
        }

        if (orderType === 1) {
          row.orders.length > 0
            ? setSalesListState(row.orders)
            : setSalesListState(null);

          setSLPageRange(round);
          setSLPaginationInfo(row.info);
        } else if (orderType === 3) {
          row.orders.length > 0
            ? setQuotListState(row.orders)
            : setQuotListState(null);

          setQuoSLPageRange(round);
          setQuoSLPaginationInfo(row.info);
        }

        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get invoice data
  const getInvoiceData = async (
    reference,
    from,
    till,
    tilldisplayStart,
    displayLength
  ) => {
    allSalesInvoiceListPOST(
      reference,
      from,
      till,
      tilldisplayStart,
      displayLength
    )
      .then((res) => {
        const row = res.data;
        let range = row.totalRecords / displayLength;
        let round = range.toFixed(0);

        if (round < range) {
          round = Number(round) + 1;
        }
        console.log(row);
        row.invoice.length > 0
          ? setInvoicesState(row.invoice)
          : setInvoicesState(null);

        setInvPageRange(round);
        setInvPaginationInfo(row.info);
        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get invoice data
  const getPaymentsData = async (
    reference,
    from,
    till,
    tilldisplayStart,
    displayLength
  ) => {
    paymentListPOST(reference, from, till, tilldisplayStart, displayLength)
      .then((res) => {
        const row = res.data;
        let range = row.totalRecords / displayLength;
        let round = range.toFixed(0);

        if (round < range) {
          round = Number(round) + 1;
        }

        row.payment.length > 0
          ? setPaymentsState(row.payment)
          : setPaymentsState(null);

        setPayPageRange(round);
        setPayPaginationInfo(row.info);
        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get invoice data
  const getDeliveryData = async (
    reference,
    from,
    till,
    tilldisplayStart,
    displayLength
  ) => {
    deliveryListPOST(reference, from, till, tilldisplayStart, displayLength)
      .then((res) => {
        const row = res.data;
        let range = row.totalRecords / displayLength;
        let round = range.toFixed(0);

        if (round < range) {
          round = Number(round) + 1;
        }

        row.delivery.length > 0
          ? setDeliveriesState(row.delivery)
          : setDeliveriesState(null);

        setDelPageRange(round);
        setDelPaginationInfo(row.info);
        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get currenvy data
  const getUser = async () => {
    setCartLoading(false);
    userDataGET()
      .then((res) => {
        setUserState(res.data);
        setSelectedAddState(res.data.addresses[0]);
        setCountryState(res.data.addresses[0].country);
        setBaseCountryState(res.data.addresses[0].baseCountry);
        setCartLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for sales pagination
  function handleSLPageChange(event) {
    let pageNumber = event.selected + 1;

    setCartLoading(false);
    getSalesData(1, SLFilter, null, null, pageNumber, selectedPageLength); //sales
  }

  function SLFiltering(event) {
    setCartLoading(false);
    setSLFilter(event.target.value);
    getSalesData(1, event.target.value, null, null, 1, selectedPageLength); //sales
  }

  //for quotation pagination
  function handleQuoPageChange(event) {
    let pageNumber = event.selected + 1;

    setCartLoading(false);
    getSalesData(3, QuoFilter, null, null, pageNumber, selectedPageLength); //quotation
  }

  function QuoFiltering(event) {
    setCartLoading(false);
    setQuoFilter(event.target.value);
    getSalesData(3, event.target.value, null, null, 1, selectedPageLength); //quotation
  }

  //for Invoice
  function InvFiltering(event) {
    setCartLoading(false);
    setInvFilter(event.target.value);
    getInvoiceData(event.target.value, null, null, 1, selectedPageLength);
  }

  function handleInvPageChange(event) {
    let pageNumber = event.selected + 1;
    setCartLoading(false);
    getInvoiceData(InvFilter, null, null, pageNumber, selectedPageLength);
  }

  //for payment
  function PayFiltering(event) {
    setCartLoading(false);
    setPayFilter(event.target.value);
    getPaymentsData(event.target.value, null, null, 1, selectedPageLength);
  }

  function handlePayPageChange(event) {
    let pageNumber = event.selected + 1;
    setCartLoading(false);
    getPaymentsData(PayFilter, null, null, pageNumber, selectedPageLength);
  }

  //for delivery
  function DelFiltering(event) {
    setCartLoading(false);
    setDelFilter(event.target.value);
    getDeliveryData(event.target.value, null, null, 1, selectedPageLength);
  }

  function handleDelPageChange(event) {
    let pageNumber = event.selected + 1;
    setCartLoading(false);
    getDeliveryData(DelFilter, null, null, pageNumber, selectedPageLength);
  }

  //===password change ====
  const onChangeCurrPwd = (e) => {
    delete formErrors["currentPwd"];
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\w\s]/gi, ""); // Regex to remove special characters
    setCurrentPassword(sanitizedValue.replace(/\s/g, ""));
  };

  const onChangeNewPwd = (e) => {
    delete formErrors["newPwd"];
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\w\s]/gi, ""); // Regex to remove special characters
    setNewPassword(sanitizedValue.replace(/\s/g, ""));
  };

  const onChangeConfirmPwd = (e) => {
    delete formErrors["confirmPwd"];
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\w\s]/gi, ""); // Regex to remove special characters
    setConfirmPassword(sanitizedValue.replace(/\s/g, ""));
  };

  const validateInput = (e) => {
    let value = e.target.value;
    value !== newPassword
      ? setError("New Password and Confirm Password does not match.")
      : setError("");
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsgs = {};

    if (!currentPassword) {
      formIsValid = false;
      errorMsgs["currentPwd"] = "Current Password is required.";
    }

    if (!newPassword) {
      formIsValid = false;
      errorMsgs["newPwd"] = "New Password is required.";
    }

    if (!confirmPassword) {
      formIsValid = false;
      errorMsgs["confirmPwd"] = "Confirm Password is required.";
    } else {
      if (newPassword !== confirmPassword) {
        formIsValid = false;
        errorMsgs["confirmPwd"] =
          "New Password and Confirm Password does not match.";
      }
    }

    setFormErrors(errorMsgs);
    return formIsValid;
  };

  const changePassword = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      submitChangePassword(currentPassword, newPassword, confirmPassword)
        .then((res) => {
          if (res.data.status == 1) {
            swal.fire({
              position: "top",
              icon: "success",
              title: "Success",
              text: "Your Password is successfully change!",
              showConfirmButton: false,
              timer: 2000,
            });

            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
          } else {
            let errorMsgs = {};

            switch (res.data.label) {
              case "currentPassword":
                errorMsgs["currentPwd"] = res.data.message;
                break;
              case "confirmPassword":
                errorMsgs["confirmPwd"] = res.data.message;
                break;
              default:
                break;
            }
            setFormErrors(errorMsgs);
          }
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage);
        });
    }
  };

  //Document header details
  const onChangeCoRegNo = (e) => {
    delete headerFormErrors["coRegNo"];
    setCoRegNo(e.target.value);
  };

  const onChangeCoVatNo = (e) => {
    delete headerFormErrors["coVatNo"];
    setCoVatNo(e.target.value);
  };

  const onChangeCoEmail = (e) => {
    delete headerFormErrors["coEmail"];
    setCoEmail(e.target.value);
  };

  const onChangeCoContact = (e) => {
    delete headerFormErrors["coContact"];
    setCoContact(e.target.value);
  };

  const onChangeCoFaxNo = (e) => {
    delete headerFormErrors["coFaxNo"];
    setCoFaxNo(e.target.value);
  };

  const handleHeaderValidation = () => {
    let formIsValid = true;
    let errorMsgs = {};

    if (!coEmail) {
      formIsValid = false;
      errorMsgs["coEmail"] = "E-mail is required.";
    }

    if (!coContact) {
      formIsValid = false;
      errorMsgs["coContact"] = "Contact No is required.";
    }

    setHeaderFormErrors(errorMsgs);
    return formIsValid;
  };

  const changeHeaderDetails = (e) => {
    e.preventDefault();

    if (handleHeaderValidation()) {
      let headerId = coHeader != null ? coHeader.headerId : 0;

      saveDocHeaderData(headerId, coRegNo, coVatNo, coEmail, coContact, coFaxNo)
        .then((res) => {
          if (res.data.status == 1) {
            swal.fire({
              position: "top",
              icon: "success",
              title: "Success",
              text: "Header Details successfully updated!",
              showConfirmButton: false,
              timer: 2000,
            });
            getInvoiceData("", null, null, 1, 20);
          }
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage);
        });
    }
  };

  //get third part customer doc header
  const getTpCDocHeader = async () => {
    getTpCustomerDcoHeaderData()
      .then((res) => {
        if (res.data.length != 0) {
          setCoHeader(res.data);
          setCoRegNo(res.data.companyRegNo);
          setCoVatNo(res.data.vatRegNo);
          setCoEmail(res.data.email);
          setCoContact(res.data.contactNo);
          setCoFaxNo(res.data.faxNo);
        } else {
          setCoHeader(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      await getUser();
      await getTpCDocHeader();
      await getSalesData(1, "", null, null, 1, 20); //sales
      await getSalesData(3, "", null, null, 1, 20); //quotation
      await getInvoiceData("", null, null, 1, 20);
      await getPaymentsData("", null, null, 1, 20);
      await getDeliveryData("", null, null, 1, 20);
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {/* breadcrumb__area-start */}
      <section className="breadcrumb__area box-plr-75">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Account
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb__area-end */}

      {/* Page Content start */}
      <main>
        <div className="account-area mb-70">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab">
                  {/* <button
                    className="tablinks active"
                    onClick={() => window["openCity"](window.event, "dashbord")}
                    id="defaultOpen"
                  >
                    My Dashbord
                  </button> */}
                  <button
                    className="tablinks active"
                    onClick={() => window["openCity"](window.event, "account")}
                  >
                    My Account
                  </button>
                  <button
                    className="tablinks"
                    onClick={() => window["openCity"](window.event, "orders")}
                  >
                    Orders
                  </button>
                  <button
                    className="tablinks"
                    onClick={() => window["openCity"](window.event, "qutaions")}
                  >
                    Quotations
                  </button>
                  <button
                    className="tablinks"
                    onClick={() => window["openCity"](window.event, "Invoice")}
                  >
                    Invoices
                  </button>
                  <button
                    className="tablinks"
                    onClick={() => window["openCity"](window.event, "payment")}
                  >
                    Payment
                  </button>
                  <button
                    className="tablinks"
                    onClick={() => window["openCity"](window.event, "Delivery")}
                  >
                    Deliveries
                  </button>
                  <button
                    className="tablinks"
                    onClick={() =>
                      window["openCity"](window.event, "resetPassword")
                    }
                  >
                    Change Password
                  </button>
                  <button
                    className="tablinks"
                    onClick={() =>
                      window["openCity"](window.event, "headerDetails")
                    }
                  >
                    Header Details
                  </button>
                  <button onClick={() => AuthService.logOut()}>Sign out</button>
                </div>
                <div
                  id="dashbord"
                  className="tabcontent"
                  style={{ border: "none" }}
                >
                  <div className="account-area mt-70 mb-70">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-12"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="account"
                  className="tabcontent"
                  style={{ border: "none", display: "block" }}
                >
                  <div className="container">
                    <div className="row">
                      {userState != null ? (
                        <React.Fragment>
                          <h5>Contact Details</h5>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>First Name</label>
                              <input
                                type="text"
                                value={userState.firstName}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Last Name</label>
                              <input
                                type="text"
                                value={userState.lastName}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Primary Email Address</label>
                              <input
                                type="email"
                                value={userState.email}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Secendary Email Address</label>
                              <input
                                type="email"
                                value={
                                  userState.email2 != null
                                    ? userState.email2
                                    : ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Invoice Email 1</label>
                              <input
                                type="email"
                                value={
                                  userState.invoiceEmail1 != null
                                    ? userState.invoiceEmail1
                                    : ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Invoice Email 2</label>
                              <input
                                type="email"
                                value={
                                  userState.invoiceEmail2 != null
                                    ? userState.invoiceEmail2
                                    : ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="checkout-form-list">
                              <label>Home</label>
                              <input
                                type="text"
                                value={
                                  userState.homePhone != null
                                    ? userState.homePhone
                                    : ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="checkout-form-list">
                              <label>office</label>
                              <input
                                type="text"
                                value={
                                  userState.officePhone != null
                                    ? userState.officePhone
                                    : ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="checkout-form-list">
                              <label>Mobile</label>
                              <input
                                type="text"
                                value={
                                  userState.mobilePhone != null
                                    ? userState.mobilePhone
                                    : ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="checkout-form-list">
                              <label>Address</label>
                              <input
                                type="text"
                                value={selectedAddState.line01}
                                readOnly
                              />
                            </div>
                            {selectedAddState.line02 != null ? (
                              <div className="checkout-form-list">
                                <input
                                  type="text"
                                  value={selectedAddState.line02}
                                  readOnly
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {selectedAddState.line03 != null ? (
                              <div className="checkout-form-list">
                                <input
                                  type="text"
                                  value={selectedAddState.line03}
                                  readOnly
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <div className="checkout-form-list">
                              <label>Postcode</label>
                              <input
                                type="text"
                                value={selectedAddState.postCode}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Country</label>
                              <input
                                type="text"
                                value={countryState.countryName}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Base Country</label>
                              <input
                                type="text"
                                value={baseCountryState.countryName}
                                readOnly
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div
                  id="orders"
                  className="tabcontent"
                  style={{ border: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="layout">
                          <form className="example">
                            <input
                              type="text"
                              placeholder="Enter Order No.."
                              name="search"
                              onKeyUp={(e) => SLFiltering(e)}
                            />
                            <p style={{ float: "right" }}>{SLPaginationInfo}</p>
                          </form>
                        </div>
                        <form action="#">
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="odn">Order No</th>
                                  <th className="cusf">Customer Ref</th>
                                  <th className="date">Date</th>
                                  <th className="amount">Total Amount</th>
                                  <th className="Status">Status</th>
                                  <th className="action">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!cartloading ? (
                                  <tr>
                                    <td
                                      className="spinner"
                                      style={{ textAlign: "center" }}
                                      colSpan="6"
                                    >
                                      <BeatLoader
                                        color="#d0d7d5"
                                        size={10}
                                        margin={28}
                                      />
                                    </td>
                                  </tr>
                                ) : salesListState != null ? (
                                  salesListState.map((el) => {
                                    return (
                                      <tr key={el.salesOrderId}>
                                        <td className="odn">
                                          <a>{el.salesOrderNumber}</a>
                                        </td>
                                        <td className="cusf">
                                          <a>
                                            {el.customerRef != null
                                              ? el.customerRef
                                              : ""}
                                          </a>
                                        </td>
                                        <td className="date">
                                          <a>{el.orderDate}</a>
                                        </td>
                                        <td className="amount">
                                          <a>
                                            {UserService.numberFormat(el.total)}
                                          </a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                        <td className="action">
                                          <Link
                                            to={"/order/" + el.salesOrderNumber}
                                          >
                                            View
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <div>
                              <nav
                                aria-label="Page navigation comments"
                                className="mt-4"
                              >
                                <ReactPaginate
                                  previousLabel="previous"
                                  nextLabel="next"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  pageCount={SLPageRange}
                                  pageRangeDisplayed={4}
                                  marginPagesDisplayed={2}
                                  onPageChange={(e) => handleSLPageChange(e)}
                                  containerClassName="pagination justify-content-center"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  activeClassName="active"
                                  // eslint-disable-next-line no-unused-vars
                                />
                              </nav>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="qutaions"
                  className="tabcontent"
                  style={{ border: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="layout">
                          <form className="example">
                            <input
                              type="text"
                              placeholder="Enter Quotation No.."
                              name="search"
                              onKeyUp={(e) => QuoFiltering(e)}
                            />
                            <p style={{ float: "right" }}>
                              {QuoPaginationInfo}
                            </p>
                          </form>
                        </div>
                        <form>
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="qutaions_no">Quotation No</th>
                                  <th className="cusf">Customer Ref</th>
                                  <th className="date">Date</th>
                                  <th className="amount">Total Amount</th>
                                  <th className="Status">Status</th>
                                  <th className="action">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!cartloading ? (
                                  <tr>
                                    <td
                                      className="spinner"
                                      style={{ textAlign: "center" }}
                                      colSpan="6"
                                    >
                                      <BeatLoader
                                        color="#d0d7d5"
                                        size={10}
                                        margin={28}
                                      />
                                    </td>
                                  </tr>
                                ) : quotListState != null ? (
                                  quotListState.map((el) => {
                                    return (
                                      <tr key={el.salesOrderId}>
                                        <td className="odn">
                                          <a>{el.salesOrderNumber}</a>
                                        </td>
                                        <td className="cusf">
                                          <a>
                                            {el.customerRef != null
                                              ? el.customerRef
                                              : ""}
                                          </a>
                                        </td>
                                        <td className="date">
                                          <a>{el.orderDate}</a>
                                        </td>
                                        <td className="amount">
                                          <a>
                                            {UserService.numberFormat(el.total)}
                                          </a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                        <td className="action">
                                          <Link
                                            to={"/order/" + el.salesOrderNumber}
                                          >
                                            View
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <div>
                              <nav
                                aria-label="Page navigation comments"
                                className="mt-4"
                              >
                                <ReactPaginate
                                  previousLabel="previous"
                                  nextLabel="next"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  pageCount={QuoPageRange}
                                  pageRangeDisplayed={4}
                                  marginPagesDisplayed={2}
                                  onPageChange={(e) => handleQuoPageChange(e)}
                                  containerClassName="pagination justify-content-center"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  activeClassName="active"
                                  // eslint-disable-next-line no-unused-vars
                                />
                              </nav>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="Invoice"
                  className="tabcontent"
                  style={{ border: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="layout">
                          <form className="example">
                            <input
                              type="text"
                              placeholder="Enter Order No.."
                              name="search"
                              onKeyUp={(e) => InvFiltering(e)}
                            />
                            <p style={{ float: "right" }}>
                              {InvPaginationInfo}
                            </p>
                          </form>
                        </div>
                        <form action="#">
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="Invoice_no">Invoice No</th>
                                  <th className="date">Date</th>
                                  <th className="amount">Total Amount</th>
                                  <th className="Status">Status</th>
                                  <th className="action">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!cartloading ? (
                                  <tr>
                                    <td
                                      className="spinner"
                                      style={{ textAlign: "center" }}
                                      colSpan="6"
                                    >
                                      <BeatLoader
                                        color="#d0d7d5"
                                        size={10}
                                        margin={28}
                                      />
                                    </td>
                                  </tr>
                                ) : invoicesState != null ? (
                                  invoicesState.map((el, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="Invoice_no">
                                          <a>{el.invoiceNo}</a>
                                        </td>
                                        <td className="date">
                                          <a>{el.invoiceDate}</a>
                                        </td>
                                        <td className="amount">
                                          {UserService.numberFormat(el.total)}
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                        <td className="action">
                                          <a
                                            href={el.printUrl}
                                            target="_blank"
                                            className="tp-btn-h1"
                                            style={{
                                              fontSize: "14px",
                                              padding: "3px 5px",
                                              width: "110px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            Orginal Print
                                          </a>
                                          {el.cusPrintUrl.length != 0 ? (
                                            <React.Fragment>
                                              <br />
                                              <a
                                                href={el.cusPrintUrl}
                                                target="_blank"
                                                className="tp-btn-h1"
                                                style={{
                                                  fontSize: "14px",
                                                  padding: "3px 5px",
                                                  width: "110px",
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                Cutomer Print
                                              </a>
                                            </React.Fragment>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <div>
                              <nav
                                aria-label="Page navigation comments"
                                className="mt-4"
                              >
                                <ReactPaginate
                                  previousLabel="previous"
                                  nextLabel="next"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  pageCount={InvPageRange}
                                  pageRangeDisplayed={4}
                                  marginPagesDisplayed={2}
                                  onPageChange={(e) => handleInvPageChange(e)}
                                  containerClassName="pagination justify-content-center"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  activeClassName="active"
                                  // eslint-disable-next-line no-unused-vars
                                />
                              </nav>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="payment"
                  className="tabcontent"
                  style={{ border: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="layout">
                          <form className="example">
                            <input
                              type="text"
                              placeholder="Enter Inovice No Last Block.."
                              name="search"
                              onKeyUp={(e) => PayFiltering(e)}
                            />
                            <p style={{ float: "right" }}>
                              {PayPaginationInfo}
                            </p>
                          </form>
                        </div>
                        <form>
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="Invoice_no">Invoice No</th>
                                  <th className="payment_m">Payment Method</th>
                                  <th className="payment_date">Payment Date</th>
                                  <th className="amount">Total Amount</th>
                                  <th className="currency">Currency</th>
                                  <th className="Status">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!cartloading ? (
                                  <tr>
                                    <td
                                      className="spinner"
                                      style={{ textAlign: "center" }}
                                      colSpan="6"
                                    >
                                      <BeatLoader
                                        color="#d0d7d5"
                                        size={10}
                                        margin={28}
                                      />
                                    </td>
                                  </tr>
                                ) : paymentsState != null ? (
                                  paymentsState.map((el, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="odn">
                                          <a>{el.invoiceNo}</a>
                                        </td>
                                        <td className="product-details">
                                          <a>{el.paymentMethod}</a>
                                        </td>
                                        <td className="cusf">
                                          <a>{el.paymentDate}</a>
                                        </td>
                                        <td className="date">
                                          <a>
                                            {UserService.numberFormat(
                                              el.amount
                                            )}
                                          </a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.currency}</a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <nav
                              aria-label="Page navigation comments"
                              className="mt-4"
                            >
                              <ReactPaginate
                                previousLabel="previous"
                                nextLabel="next"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={PayPageRange}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={2}
                                onPageChange={(e) => handlePayPageChange(e)}
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                                // eslint-disable-next-line no-unused-vars
                              />
                            </nav>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="Delivery"
                  className="tabcontent"
                  style={{ border: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="layout">
                          <form className="example">
                            <input
                              type="text"
                              placeholder="Enter Order No.."
                              name="search"
                              onKeyUp={(e) => DelFiltering(e)}
                            />
                            <p style={{ float: "right" }}>
                              {DelPaginationInfo}
                            </p>
                          </form>
                        </div>
                        <form action="#">
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="delivery_type">
                                    Delivery Type
                                  </th>
                                  <th className="delivery_date">
                                    Delivery Date
                                  </th>
                                  <th className="Quantity">Quantity</th>
                                  <th className="delivery_address">
                                    Delivery Address
                                  </th>
                                  <th className="Status">Status</th>
                                  <th className="Status">Delivery Note</th>
                                  <th className="action">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!cartloading ? (
                                  <tr>
                                    <td
                                      className="spinner"
                                      style={{ textAlign: "center" }}
                                      colSpan="6"
                                    >
                                      <BeatLoader
                                        color="#d0d7d5"
                                        size={10}
                                        margin={28}
                                      />
                                    </td>
                                  </tr>
                                ) : deliveriesState != null ? (
                                  deliveriesState.map((el, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="delivery_type">
                                          <a>
                                            {el.type === 1
                                              ? "Woodalls"
                                              : "Home Delivery"}
                                          </a>
                                        </td>
                                        <td className="delivery_date">
                                          <a>{el.deliveryDate}</a>
                                        </td>
                                        <td className="Quantity">
                                          <a>{el.deliveredQty}</a>
                                        </td>
                                        <td className="delivery_address">
                                          <a>{el.deliveryAddress}</a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                        <td className="action">
                                          {el.deliveryNote != null ? (
                                            <a
                                              href={el.deliveryNote[0].url}
                                              target="_blank"
                                              style={{ color: "green" }}
                                            >
                                              Print
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td className="action">
                                          <Link
                                            to={"/delivery/" + el.deliveryId}
                                            style={{ color: "green" }}
                                          >
                                            View
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={7}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <ReactPaginate
                              previousLabel="previous"
                              nextLabel="next"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={DelPageRange}
                              pageRangeDisplayed={4}
                              marginPagesDisplayed={2}
                              onPageChange={(e) => handleDelPageChange(e)}
                              containerClassName="pagination justify-content-center"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              activeClassName="active"
                              // eslint-disable-next-line no-unused-vars
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="resetPassword"
                  className="tabcontent"
                  style={{ border: "none", display: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <Form onSubmit={changePassword} ref={form}>
                        {userState != null ? (
                          <React.Fragment>
                            <h5>Change Password</h5>
                            <div className="col-md-12">
                              <div className="checkout-form-list">
                                <label>Current Password:</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="checkout-form-list">
                                <input
                                  type="password"
                                  value={currentPassword}
                                  onChange={onChangeCurrPwd}
                                  maxLength={12}
                                />
                                <span className="error-span currentPwdspan">
                                  {formErrors["currentPwd"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-12">
                              <div className="checkout-form-list">
                                <label>New Password</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="checkout-form-list">
                                <input
                                  type="password"
                                  value={newPassword}
                                  onChange={onChangeNewPwd}
                                  maxLength={12}
                                />
                                <span className="error-span">
                                  {formErrors["newPwd"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-12">
                              <div className="checkout-form-list">
                                <label>Confirm Password</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="checkout-form-list">
                                <input
                                  type="password"
                                  value={confirmPassword}
                                  onChange={onChangeConfirmPwd}
                                  onBlur={validateInput}
                                  maxLength={12}
                                />
                                <span className="error-span">
                                  {formErrors["confirmPwd"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-6 order-button-payment mt-20">
                              <CheckButton
                                type="submit"
                                className="tp-btn-h1"
                                ref={checkBtn}
                              >
                                Submit
                              </CheckButton>
                            </div>
                            <div className="col-md-6"></div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </Form>
                    </div>
                  </div>
                </div>

                <div
                  id="headerDetails"
                  className="tabcontent"
                  style={{ border: "none", display: "none" }}
                >
                  <div className="container">
                    <div className="row">
                      <Form onSubmit={changeHeaderDetails} ref={form}>
                        {/* {userState != null ? (
                          <React.Fragment> */}
                        <h5>Change Header Details</h5>
                        {/* <div className="col-md-12">
                              <div className="checkout-form-list">
                                <label>Company Reg No:</label>
                              </div>
                            </div> */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Company Reg No:</label>
                              <input
                                type="text"
                                value={coRegNo}
                                onChange={onChangeCoRegNo}
                                maxLength={30}
                              />
                              <span className="error-span currentPwdspan">
                                {headerFormErrors["coRegNo"]}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Company Vat No:</label>
                              <input
                                type="text"
                                value={coVatNo}
                                onChange={onChangeCoVatNo}
                                maxLength={30}
                              />
                              <span className="error-span currentPwdspan">
                                {headerFormErrors["coVatNo"]}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>E-mail:</label>
                              <input
                                type="text"
                                value={coEmail}
                                onChange={onChangeCoEmail}
                                maxLength={100}
                              />
                              <span className="error-span currentPwdspan">
                                {headerFormErrors["coEmail"]}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Contact No:</label>
                              <input
                                type="text"
                                value={coContact}
                                onChange={onChangeCoContact}
                                maxLength={20}
                              />
                              <span className="error-span currentPwdspan">
                                {headerFormErrors["coContact"]}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="checkout-form-list">
                              <label>Fax No:</label>
                              <input
                                type="text"
                                value={coFaxNo}
                                onChange={onChangeCoFaxNo}
                                maxLength={30}
                              />
                              <span className="error-span currentPwdspan">
                                {headerFormErrors["coFaxNo"]}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6"></div>
                        </div>

                        <div className="col-md-6 order-button-payment mt-20">
                          <CheckButton
                            type="submit"
                            className="tp-btn-h1"
                            ref={checkBtn}
                          >
                            Submit
                          </CheckButton>
                        </div>
                        <div className="col-md-6"></div>
                        {/* </React.Fragment>
                        ) : (
                          ""
                        )} */}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Page Content end */}
      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
