export function loadScript(srcArr) {
  if (!srcArr) {
    srcArr = [
      "../assets/js/vendor/jquery.js",
      "../assets/js/vendor/waypoints.js",
      "../assets/js/bootstrap-bundle.js",
      "../assets/js/meanmenu.js",
      "../assets/js/swiper-bundle.js",
      "../assets/js/owl-carousel.js",
      "../assets/js/magnific-popup.js",
      "../assets/js/countdown.min.js",
      "../assets/js/counterup.js",
      "../assets/js/wow.js",
      "../assets/js/backtotop.js",
      "../assets/js/nice-select.js",
      "../assets/js/isotope-pkgd.js",
      "../assets/js/imagesloaded-pkgd.js",
      "../assets/js/main.js",
      // "../assets/js/search.js",
    ];
  }
  srcArr.forEach((src) => {
    let tag = document.createElement("script");
    tag.type = "text/javascript";
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName("body")[0].appendChild(tag);
  });
}
