import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import UserService from "../services/user.service";

import cart from "../common/cart";
import useCartStore from "../common/globalStore";

export default function Promotion() {
  const [promoState, setPromoState] = useState([]);
  const getCartData = useCartStore((state) => state.updateCart);

  const promotionData = async () => {
    const customerId = UserService.user.customerId;
    const displayStart = 1;
    const displayLength = 10;
    const res = await axios
      .post(
        UserService.api_Url + "api/app/product/todayDeals/",
        {
          customerId,
          displayStart,
          displayLength,
        },
        { headers: UserService.header }
      )
      .catch((err) => {
        console.log(err);
      });
    setPromoState(res.data.products);
  };

  //add to cart function
  function fnAddToCart(productId, modelId, qty, price) {
    cart.addToCart(productId, modelId, qty, price);
    setTimeout(
      function () {
        //Start the timer
        getCartData();
      }.bind(this),
      1000
    );
  }

  useEffect(() => {
    promotionData();
  }, []);

  return (
    <React.Fragment>
      {promoState.length > 0 ? (
        <section className="trending-product-area light-bg-s pt-25 pb-15">
          <div className="container custom-conatiner">
            <div className="row">
              <div className="col-xl-12">
                <div className="section__head d-flex justify-content-between mb-30">
                  <div className="section__title section__title-2">
                    <h5 className="st-titile">Promotions</h5>
                  </div>
                  <div className="button-wrap button-wrap-2">
                    {/* <a href="#">
                      See All Product <i className="fal fa-chevron-right"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {promoState.map((el) => {
                let price =
                  el.variantCount === 0
                    ? el.specialPrice === 0
                      ? el.orginalPrice
                      : el.specialPrice
                    : 0;
                return (
                  <div
                    key={el.productId + "@" + el.modelId}
                    className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2"
                  >
                    <div className="product__item product__item-2 b-radius-2 mb-20">
                      <div className="product__thumb fix">
                        <div className="product-image w-img">
                          <Link to={"/product/" + el.sku}>
                            <img src={el.defaultImage} alt="product" />
                          </Link>
                        </div>
                        <div className="product__offer">
                          {/* <span className="discount">-15%</span> */}
                        </div>
                        <div className="product-action product-action-2">
                          <Link
                            to={"/product/" + el.sku}
                            className="icon-box icon-box-1"
                          >
                            <i className="fal fa-eye"></i>
                            <i className="fal fa-eye"></i>
                          </Link>
                          {/* <a href="#" className="icon-box icon-box-1">
                          <i className="fal fa-heart"></i>
                          <i className="fal fa-heart"></i>
                        </a>
                        <a href="#" className="icon-box icon-box-1">
                          <i className="fal fa-layer-group"></i>
                          <i className="fal fa-layer-group"></i>
                        </a> */}
                        </div>
                      </div>
                      <div className="product__content product__content-2">
                        <h6>
                          <Link to={"/product/" + el.sku}>
                            {el.productName}
                            {el.variantCount > 0 ? " (Variants) " : ""}
                          </Link>
                        </h6>

                        <div className="price">
                          {el.variantCount === 0 ? (
                            <span>{UserService.numberFormat(price)}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {el.variantCount === 0 ? (
                        <div className="product__add-cart text-center">
                          <button
                            type="button"
                            className="cart-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center w-100"
                            onClick={() =>
                              fnAddToCart(el.productId, el.modelId, 1, price)
                            }
                          >
                            Add to Cart
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

// export default class Promotion1 extends Component {

//     constructor() {
//         super();
//         this.state = {
//             promoState: [],
//             isLoading: true
//         }
//     }

//     componentDidMount() {
//         this.promotionData();
//     }

//     promotionData = async () => {
//         const customerId = UserService.user.customerId;
//         const displayStart = 1;
//         const displayLength = 10;
//         const res = await axios
//             .post(
//                 UserService.api_Url + "api/app/product/todayDeals/",
//                 {
//                     customerId,
//                     displayStart,
//                     displayLength
//                 },
//                 { headers: UserService.header }
//             );
//         this.setState({
//             promoState: res.data.products
//         });
//     };

//     render() {
//         return (
//             <React.Fragment>
//                 <section className="trending-product-area light-bg-s pt-25 pb-15">
//                     <div className="container custom-conatiner">
//                         <div className="row">
//                             <div className="col-xl-12">
//                                 <div className="section__head d-flex justify-content-between mb-30">
//                                     <div className="section__title section__title-2">
//                                         <h5 className="st-titile">Promotions</h5>
//                                     </div>
//                                     <div className="button-wrap button-wrap-2">
//                                         <a href="#">See All Product <i className="fal fa-chevron-right"></i></a>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="row">

//                             {promoState.map(el => {
//                                 return (
//                                     <div key={el.productId+"@"+el.modelId} className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
//                                         <div className="product__item product__item-2 b-radius-2 mb-20">
//                                             <div className="product__thumb fix">
//                                                 <div className="product-image w-img">
//                                                     <Link to={'/product'} >
//                                                         <img src={el.defaultImage} alt="product" />
//                                                     </Link>
//                                                 </div>
//                                                 <div className="product__offer">
//                                                     {/* <span className="discount">-15%</span> */}
//                                                 </div>
//                                                 <div className="product-action product-action-2">
//                                                     <a href="#" className="icon-box icon-box-1" data-bs-toggle="modal" data-bs-target="#productModalId">
//                                                         <i className="fal fa-eye"></i>
//                                                         <i className="fal fa-eye"></i>
//                                                     </a>
//                                                     <a href="#" className="icon-box icon-box-1">
//                                                         <i className="fal fa-heart"></i>
//                                                         <i className="fal fa-heart"></i>
//                                                     </a>
//                                                     <a href="#" className="icon-box icon-box-1">
//                                                         <i className="fal fa-layer-group"></i>
//                                                         <i className="fal fa-layer-group"></i>
//                                                     </a>
//                                                 </div>
//                                             </div>
//                                             <div className="product__content product__content-2">
//                                                 <h6>
//                                                     <a href="#.html">
//                                                         {el.productName}
//                                                         {el.variantCount > 0 ? (" (Variants) ") :""}
//                                                     </a>
//                                                 </h6>
//                                                 <div className="rating mb-5 mt-10">
//                                                     <ul>
//                                                         <li><a href="#"><i className="fal fa-star"></i></a></li>
//                                                         <li><a href="#"><i className="fal fa-star"></i></a></li>
//                                                         <li><a href="#"><i className="fal fa-star"></i></a></li>
//                                                         <li><a href="#"><i className="fal fa-star"></i></a></li>
//                                                         <li><a href="#"><i className="fal fa-star"></i></a></li>
//                                                     </ul>
//                                                     <span>(05 review)</span>
//                                                 </div>
//                                                 <div className="price">
//                                                     <span>{el.variantCount === 0 ? (el.specialPrice === 0 ? UserService.numberFormat(el.orginalPrice) : UserService.numberFormat(el.specialPrice)) : ""}</span>
//                                                 </div>
//                                             </div>
//                                             {el.variantCount === 0 ?
//                                                 <div className="product__add-cart text-center">
//                                                     <button type="button" className="cart-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center w-100">
//                                                         Add to Cart
//                                                     </button>
//                                                 </div>
//                                                 : ""
//                                             }
//                                         </div>
//                                     </div>
//                                 );
//                             })}

//                         </div>
//                     </div>
//                 </section>
//             </React.Fragment>
//         );
//     }
// }
