import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserService from "./user.service";
import axios from "axios";

const PrivateRoute = () => {
  let urlPath = window.location.pathname;

  const auth = UserService.header; // determine if authorized, from context or however you're doing it
  if (auth) {
    const tokenval = getTokenValidation();
    if (typeof tokenval["accessToken"] === undefined) {
      if (urlPath.includes("product")) {
        let urlArray = urlPath.split("/");
        let sku = urlArray[urlArray.length - 1];
        let url = "/login?sku=" + sku;

        return <Navigate to={url} />;
      } else {
        return <Navigate to="/login" />;
      }
    } else {
      return <Outlet />;
    }
  } else {
    if (urlPath.includes("product")) {
      let urlArray = urlPath.split("/");
      let sku = urlArray[urlArray.length - 1];
      let url = "/login?sku=" + sku;

      return <Navigate to={url} />;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

//Token validation
const getTokenValidation = async () => {
  const res = await fetch(UserService.api_Url + "api/app/loginCheck/TF", {
    headers: UserService.header,
  });
  const data = await res.json();
  return data;
};

export default PrivateRoute;
