import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getBrands } from "../services/apiServices";

export default function Brand() {
  const [brandState, setBrandState] = useState([]);

  //get product categories
  const brandData = async () => {
    getBrands()
      .then((res) => {
        setBrandState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      brandData();
    })();
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className="container custom-conatiner">
          <div className="row">
            <div className="col-12">
              <div className="section__head d-flex justify-content-between mb-30">
                <div className="section__title section__title-2">
                  <h5 className="st-titile">Brands</h5>
                </div>
              </div>

              {brandState.map((bd) => {
                return (
                  <Link to={"/brand/" + bd.brandId} key={bd.brandId}>
                    <img
                      src={bd.image}
                      alt={bd.name}
                      style={{
                        width: "200px",
                        padding: "10px",
                        margin: "20px",
                      }}
                    />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
