import axios from "axios";
import authHeader from "./auth-header";

const api_Url = "https://erp.woodalls.co.uk/";
// const api_Url = "http://52.56.217.109/";
// const api_Url = "http://localhost:8181/WoodallsERP/";
const header = JSON.parse(localStorage.getItem("headers"));
const user = JSON.parse(localStorage.getItem("user"));

const getPublicContent = () => {
  return axios.get(api_Url + "all");
};

function numberFormat(value) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "GBP",
  }).format(value);
}

const UserService = {
  api_Url,
  header,
  user,
  numberFormat,
};

export default UserService;
