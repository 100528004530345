import React, { useState, useEffect, useRef } from "react";
import UserService from "../services/user.service";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Header from "../components/header-component";
import Footer from "../components/footer";
import {
  countryList2GET,
  getTpCustomers,
  quotToSalesGET,
  salesDeliveryListGET,
  salesDetailsPOST,
  salesInvoiceListGET,
  salesInvoicePaymentGET,
  salesReturnItemsListGET,
  saveTpCustomer,
} from "../services/apiServices";
import { PulseLoader } from "react-spinners";
import swal from "sweetalert2";

import "../resources/css/viewoder.css";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

export default function order() {
  const { orderNo } = useParams();
  let navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(false);
  const [label, setLable] = useState("Order");
  const [cusAddress, setCusAddress] = useState(false);
  const [delAddress, setDelCusAddress] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [invoicesState, setInvoicesState] = useState(null);
  const [paymentsState, setPaymentsState] = useState(null);
  const [deliveriesState, setDeliveriesState] = useState(null);
  const [returnItemState, setReturnItemState] = useState(null);

  //Third Party Customer
  const [showModal, setShowModal] = useState(false);
  const [addnewCustomerForm, setAddnewCustomerForm] = useState(false);
  const [countryState, setCountryState] = useState(null);
  const [tpCustomerState, setTpCustomerState] = useState(null);

  const [mappedTpcCustomerId, setMappedTpcCustomerId] = useState(0);
  const [tpcCustomerId, setTpcCustomerId] = useState(0);
  const [tpcName, setTpcName] = useState("");
  const [tpcEmail, setTpcEmail] = useState("");
  const [tpcContact, setTpcContact] = useState("");
  const [tpcAddLine1, setTpcAddLine1] = useState("");
  const [tpcAddLine2, setTpcAddLine2] = useState("");
  const [tpcAddLine3, setTpcAddLine3] = useState("");
  const [tpcPostCode, setTpcPostCode] = useState("");
  const [tpcCountry, setTpcCountry] = useState(9);
  const [formErrors, setFormErrors] = useState([]);

  const orderData = async (orderNo) => {
    salesDetailsPOST(orderNo)
      .then((res) => {
        let data = res.data;

        if (data.salesOrderId != 0) {
          setOrder(res.data);
          setCusAddress(data.customerAddress);
          setDelCusAddress(data.deliveryAddress);
          setOrderItems(data.products);
          setMappedTpcCustomerId(res.data.tpCustomerId);
          setTpcCustomerId(res.data.tpCustomerId);
          if (data.orderType != "Sales Order") setLable("Quotation");

          getInvoiceData(data.salesOrderId);
          getPaymentsData(data.salesOrderId);
          getDeliveryData(data.salesOrderId);
          getReturnItemData(data.salesOrderId);

          setLoading(true);
        } else {
          navigate("/page-not-found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get invoice data
  const getInvoiceData = async (orderId) => {
    salesInvoiceListGET(orderId)
      .then((res) => {
        const row = res.data;
        row.length > 0 ? setInvoicesState(row) : setInvoicesState(null);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get invoice data
  const getPaymentsData = async (orderId) => {
    salesInvoicePaymentGET(orderId)
      .then((res) => {
        const row = res.data;
        row.length > 0 ? setPaymentsState(row) : setPaymentsState(null);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get invoice data
  const getDeliveryData = async (orderId) => {
    salesDeliveryListGET(orderId)
      .then((res) => {
        const row = res.data;
        row.length > 0 ? setDeliveriesState(row) : setDeliveriesState(null);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get return Items data
  const getReturnItemData = async (orderId) => {
    salesReturnItemsListGET(orderId)
      .then((res) => {
        const row = res.data;
        row.length > 0 ? setReturnItemState(row) : setReturnItemState(null);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //change Quotation to sales
  const changeQuoToSales = async (quotId) => {
    quotToSalesGET(quotId)
      .then((res) => {
        swal.fire({
          position: "top",
          icon: "success",
          title: "Success",
          text: "Convert Quotation to Sales!",
          showConfirmButton: false,
          timer: 2000,
        });
        navigate("/order/" + res.data.referenceId);
        window.location.reload(true);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get country data
  const getCountry = async () => {
    countryList2GET()
      .then((res) => {
        setCountryState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get country data
  const getTpCustomer = async () => {
    getTpCustomers()
      .then((res) => {
        console.log(res.data);
        setTpCustomerState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Third Party Customer
  const onChangeTcpCustomerId = (e) => {
    delete formErrors["tpcCustomerId"];
    setTpcCustomerId(e.target.value);
  };

  const onChangeTpcName = (e) => {
    delete formErrors["tpcName"];
    setTpcName(e.target.value);
  };

  const onChangeTpcEmail = (e) => {
    delete formErrors["tpcEmail"];
    setTpcEmail(e.target.value);
  };

  const onChangeTpcContact = (e) => {
    delete formErrors["tpcContact"];
    setTpcContact(e.target.value);
  };

  const onChangeTpcAdd1 = (e) => {
    delete formErrors["tpcAdd1"];
    setTpcAddLine1(e.target.value);
  };

  const onChangeTpcAdd2 = (e) => {
    delete formErrors["tpcAdd2"];
    setTpcAddLine2(e.target.value);
  };

  const onChangeTpcAdd3 = (e) => {
    delete formErrors["tpcAdd3"];
    setTpcAddLine3(e.target.value);
  };

  const onChangeTpcPostCode = (e) => {
    delete formErrors["tpcPostCode"];
    setTpcPostCode(e.target.value);
  };

  const onChangeTcpCountry = (e) => {
    delete formErrors["tpcCountry"];
    setTpcCountry(e.target.value);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsgs = {};

    if (addnewCustomerForm) {
      if (!tpcName) {
        formIsValid = false;
        errorMsgs["tpcName"] = "Customer Name is required.";
      }

      if (!tpcEmail) {
        formIsValid = false;
        errorMsgs["tpcEmail"] = "E-mail is required.";
      }

      if (!tpcContact) {
        formIsValid = false;
        errorMsgs["tpcContact"] = "Contact No is required.";
      }

      if (!tpcAddLine1) {
        formIsValid = false;
        errorMsgs["tpcAdd1"] = "Address Line 1 is required.";
      }

      if (!tpcPostCode) {
        formIsValid = false;
        errorMsgs["tpcPostCode"] = "Post Code is required.";
      }

      if (tpcCountry == 0) {
        formIsValid = false;
        errorMsgs["tpcCountry"] = "Country is required.";
      }
    } else {
      if (tpcCustomerId == 0) {
        formIsValid = false;
        errorMsgs["tpcCustomerId"] = "Customer is required.";
      }
    }

    setFormErrors(errorMsgs);
    return formIsValid;
  };

  const saveNewCustomer = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      saveTpCustomer(
        order.salesOrderId,
        tpcCustomerId,
        tpcName,
        tpcEmail,
        tpcContact,
        tpcAddLine1,
        tpcAddLine2,
        tpcAddLine3,
        tpcPostCode,
        tpcCountry,
        addnewCustomerForm
      )
        .then((res) => {
          if (res.data.status == 1) {
            setShowModal(false);
            setMappedTpcCustomerId(res.data.ref_1);
            setTpcCustomerId(res.data.ref_1);
            window.open(res.data.content_1, "_blank");
            getTpCustomer();
            cleanTpcForm();
          } else if (res.data.status == 2) {
            swal.fire({
              position: "top",
              icon: "error",
              title: "Error",
              text: "Operation is unsuccessful.",
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (res.data.status == 3) {
            let errorMsgs = {};
            errorMsgs["tpcEmail"] = res.data.message;
            setFormErrors(errorMsgs);
          } else if (res.data.status == 4) {
            setShowModal(false);
            swal.fire({
              position: "top",
              icon: "error",
              title: "Error",
              text: res.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage);
        });
    }
  };

  const cleanTpcForm = () => {
    setTpcName("");
    setTpcEmail("");
    setTpcContact("");
    setTpcAddLine1("");
    setTpcAddLine2("");
    setTpcAddLine3("");
    setTpcPostCode("");
    // setCountryState(9);
  };

  useEffect(() => {
    // get product data
    (async () => {
      await orderData(orderNo);
      await getCountry();
      await getTpCustomer();
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {/* breadcrumb__area-start */}
      <section className="breadcrumb__area box-plr-75">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/my-account">My Account</a>
                    </li>
                    <li className="breadcrumb-item">Order</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {order != false ? order.salesOrderNumber : ""}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb__area-end */}

      <main>
        <div className="account-area mb-70">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tabp">
                  <button
                    className="tablinks active"
                    onClick={() => window["openCity"](window.event, "Orders")}
                    id="defaultOpen"
                  >
                    {label} Details
                  </button>
                  {order != null &&
                  order.salesOrderId != 0 &&
                  order.orderType == "Sales Order" ? (
                    <React.Fragment>
                      <button
                        className="tablinks"
                        onClick={() =>
                          window["openCity"](window.event, "Invoice")
                        }
                      >
                        Invoices
                      </button>
                      <button
                        className="tablinks"
                        onClick={() =>
                          window["openCity"](window.event, "payment")
                        }
                      >
                        Payments
                      </button>
                      <button
                        className="tablinks"
                        onClick={() =>
                          window["openCity"](window.event, "Delivery")
                        }
                      >
                        Deliveries
                      </button>

                      <button
                        className="tablinks"
                        onClick={() =>
                          window["openCity"](window.event, "Return")
                        }
                      >
                        Return Items
                      </button>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {order.status != "Canceled" ? (
                    <button
                      className="tablinks"
                      onClick={() => {
                        setShowModal(true);
                        setFormErrors({});
                        setAddnewCustomerForm(false);
                      }}
                      style={{ float: "right" }}
                    >
                      Customer Print
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  id="Orders"
                  className="tabcontent"
                  style={{ display: "block" }}
                >
                  <div className="account-area mt-70 mb-70">
                    <div className="container">
                      {order != null && order.salesOrderId != 0 ? (
                        <React.Fragment>
                          <div className="row">
                            <h5>{label} Details</h5>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          {label} No
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.salesOrderNumber}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          {label} Date
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.orderDate}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Order Type
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.orderType}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Part Delivery
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.partDelivery}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Sales Accounts
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.accountType}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Sales Person
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.salesPerson}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Status
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.status}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          {label} Total:
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {UserService.numberFormat(
                                              order.orderTotal
                                            )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Carriage
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.carriage === 0
                                              ? "N/A"
                                              : UserService.numberFormat(
                                                  order.carriage
                                                )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Collection
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.collection === 0
                                              ? "N/A"
                                              : UserService.numberFormat(
                                                  order.collection
                                                )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Connection
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.connection === 0
                                              ? "N/A"
                                              : UserService.numberFormat(
                                                  order.connection
                                                )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Amount Due-Order
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {UserService.numberFormat(
                                              order.amountDue
                                            )}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Invoice Total
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.invoiceTotal === 0
                                              ? "N/A"
                                              : UserService.numberFormat(
                                                  order.invoiceTotal
                                                )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Payment Received
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.paymentReceived === 0
                                              ? "N/A"
                                              : UserService.numberFormat(
                                                  order.paymentReceived
                                                )}
                                          </span>
                                        </td>
                                      </tr>

                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Order Return
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.orderReturns === 0
                                              ? "N/A"
                                              : UserService.numberFormat(
                                                  order.orderReturns
                                                )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Return Payback
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.returnPayBack === 0
                                              ? "N/A"
                                              : UserService.numberFormat(
                                                  order.returnPayBack
                                                )}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Include Vat
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.includeVat}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr className="solid"></hr>
                            <h5>Customer Details</h5>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Name
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.customerName}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Email
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.customerEmail}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Phone
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.customerPhone}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Address
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {cusAddress.line01}
                                            {cusAddress.line02 != null ? (
                                              <br />
                                            ) : (
                                              ""
                                            )}
                                            {cusAddress.line02 != null
                                              ? cusAddress.line02
                                              : ""}
                                            {cusAddress.line03 != null ? (
                                              <br />
                                            ) : (
                                              ""
                                            )}
                                            {cusAddress.line03 != null
                                              ? cusAddress.line03
                                              : ""}
                                            <br />
                                            {cusAddress.postCode}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr className="solid"></hr>
                            <h5>
                              Delivery Details{" "}
                              <span
                                style={{
                                  marginLeft: "20px",
                                  color: "#9ed33f",
                                  fontSize: "12px",
                                }}
                              >
                                (Please contact us to change the delivery
                                address.)
                              </span>
                            </h5>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Contact
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.deliveryContact != ""
                                              ? order.deliveryContact
                                              : "N/A"}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Phone
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.deliveryPhone != ""
                                              ? order.deliveryPhone
                                              : "N/A"}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Address
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {delAddress.line01}
                                            {delAddress.line02 != null ? (
                                              <br />
                                            ) : (
                                              ""
                                            )}
                                            {delAddress.line02 != null
                                              ? delAddress.line02
                                              : ""}
                                            {delAddress.line03 != null ? (
                                              <br />
                                            ) : (
                                              ""
                                            )}
                                            {delAddress.line03 != null
                                              ? delAddress.line03
                                              : ""}
                                            <br />
                                            {delAddress.postCode}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr className="solid"></hr>
                            <h5>Reference</h5>
                            <div className="col-md-4">
                              <div className="">
                                <div className="your-order-table table-responsive">
                                  <table>
                                    <tbody>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Customer Reference
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.customerRef != ""
                                              ? order.customerRef
                                              : "N/A"}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          Comments
                                          <strong className="product-quantity float-right">
                                            :
                                          </strong>
                                        </td>
                                        <td className="product-total">
                                          <span className="amount span-font-css">
                                            {order.comment != ""
                                              ? order.comment
                                              : "N/A"}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="">
                                <div
                                  className="table-content table-responsive"
                                  style={{ margin: "10px" }}
                                >
                                  <table
                                    className="table"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th className="odi"></th>
                                        <th className="proname">
                                          Product Name
                                        </th>
                                        <th className="status">Status</th>
                                        <th className="qty">Qty</th>
                                        <th className="price">Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {orderItems.length > 0 ? (
                                        orderItems.map((el, idx) => {
                                          return (
                                            <tr key={idx}>
                                              <td className="odi">
                                                <img
                                                  src={el.imageUrl}
                                                  style={{ width: "60px" }}
                                                />
                                              </td>
                                              <td
                                                className="proname"
                                                style={{
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                <Link to={"/product/" + el.sku}>
                                                  {el.productName}
                                                </Link>
                                                {el.promotion != null ? (
                                                  <span
                                                    style={{
                                                      color: "red",
                                                      fontSize: "10px",
                                                    }}
                                                  >
                                                    <br />
                                                    {el.promotion.promotion
                                                      .type == 1
                                                      ? UserService.numberFormat(
                                                          el.promotion.value
                                                        )
                                                          .toString()
                                                          .slice(0, -3) +
                                                        el.promotionExpiration
                                                      : el.promotion.promotion
                                                          .type == 2
                                                      ? el.promotionExpiration
                                                      : ""}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                                {el.group == true ? (
                                                  <>
                                                    <br />
                                                    <span
                                                      style={{
                                                        color: "#34aadc",
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      {el.groupLabel}
                                                    </span>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td className="status">
                                                <a
                                                  style={{
                                                    color: "rgb(255, 0, 0)",
                                                  }}
                                                >
                                                  {el.status}
                                                </a>
                                              </td>
                                              <td className="qty">
                                                <a>{el.quantity}</a>
                                              </td>
                                              <td className="price">
                                                <a className="amount">
                                                  {UserService.numberFormat(
                                                    el.sellingPrice
                                                  )}
                                                </a>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan={5}>
                                            No records available!
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            {order != null &&
                            order.salesOrderId != 0 &&
                            order.orderType != "Sales Order" ? (
                              <div className="col-md-12">
                                <button
                                  type="button"
                                  className="cart-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center w-100"
                                  onClick={() =>
                                    changeQuoToSales(order.salesOrderId)
                                  }
                                >
                                  To Sales
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                {/* invoice */}
                <div id="Invoice" className="tabcontent">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <form action="#">
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="Invoice_no">Invoice No</th>
                                  <th className="date">Date</th>
                                  <th className="amount">Total Amount</th>
                                  <th className="Status">Status</th>
                                  <th className="action">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoicesState != null ? (
                                  invoicesState.map((el, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="Invoice_no">
                                          <a>{el.invoiceNo}</a>
                                        </td>
                                        <td className="date">
                                          <a>{el.invoiceDate}</a>
                                        </td>
                                        <td className="amount">
                                          {UserService.numberFormat(el.total)}
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                        <td className="action">
                                          <a
                                            href={el.printUrl}
                                            target="_blank"
                                            className="tp-btn-h1"
                                            style={{
                                              fontSize: "14px",
                                              padding: "3px 5px",
                                              width: "110px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            Orginal Print
                                          </a>
                                          {el.cusPrintUrl.length != 0 ? (
                                            <React.Fragment>
                                              <br />
                                              <a
                                                href={el.cusPrintUrl}
                                                target="_blank"
                                                className="tp-btn-h1"
                                                style={{
                                                  fontSize: "14px",
                                                  padding: "3px 5px",
                                                  width: "110px",
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                Cutomer Print
                                              </a>
                                            </React.Fragment>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {/* payment */}
                <div id="payment" className="tabcontent">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <form>
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="Invoice_no">Invoice No</th>
                                  <th className="payment_m">Payment Method</th>
                                  <th className="payment_date">Payment Date</th>
                                  <th className="amount">Total Amount</th>
                                  <th className="currency">Currency</th>
                                  <th className="Status">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentsState != null ? (
                                  paymentsState.map((el, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="odn">
                                          <a>{el.invoiceNo}</a>
                                        </td>
                                        <td className="product-details">
                                          <a>{el.paymentMethod}</a>
                                        </td>
                                        <td className="cusf">
                                          <a>{el.paymentDate}</a>
                                        </td>
                                        <td className="date">
                                          <a>
                                            {UserService.numberFormat(
                                              el.amount
                                            )}
                                          </a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.currency}</a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={6}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {/* delivery */}
                <div id="Delivery" className="tabcontent">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <form action="#">
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th className="delivery_type">
                                    Delivery Type
                                  </th>
                                  <th className="delivery_date">
                                    Delivery Date
                                  </th>
                                  <th className="Quantity">Quantity</th>
                                  <th className="delivery_address">
                                    Delivery Address
                                  </th>
                                  <th className="Status">Status</th>
                                  <th className="Status">Delivery Note</th>
                                  <th className="action">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {deliveriesState != null ? (
                                  deliveriesState.map((el, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td className="delivery_type">
                                          <a>
                                            {el.type === 1
                                              ? "Woodalls"
                                              : "Home Delivery"}
                                          </a>
                                        </td>
                                        <td className="delivery_date">
                                          <a>{el.deliveryDate}</a>
                                        </td>
                                        <td className="Quantity">
                                          <a>{el.deliveredQty}</a>
                                        </td>
                                        <td className="delivery_address">
                                          <a>{el.deliveryAddress}</a>
                                        </td>
                                        <td className="Status">
                                          <a>{el.status}</a>
                                        </td>
                                        <td className="action">
                                          {el.deliveryNote != null ? (
                                            <a
                                              href={el.deliveryNote[0].url}
                                              target="_blank"
                                              style={{ color: "green" }}
                                            >
                                              Print
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td className="action">
                                          <Link
                                            to={"/delivery/" + el.deliveryId}
                                            style={{ color: "green" }}
                                          >
                                            View
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={7}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {/* return item */}
                <div id="Return" className="tabcontent">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <form action="#">
                          <div
                            className="table-content table-responsive"
                            style={{ margin: "10px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "10px" }}
                            >
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>Date</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {returnItemState != null ? (
                                  returnItemState.map((el, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td>
                                          <a>{el.productName}</a>
                                        </td>
                                        <td>
                                          <a>{el.date}</a>
                                        </td>
                                        <td>
                                          <a>{el.quantity}</a>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={7}>No records available!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {showModal == true ? (
                  <React.Fragment>
                    <div id="myModal" className="modal">
                      {/* <!-- Modal content --> */}
                      <div className="modal-content">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Third Party Customer</p>
                          </div>
                          <div className="col-md-6">
                            <span
                              className="close"
                              onClick={() => setShowModal(false)}
                            >
                              &times;
                            </span>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <Form onSubmit={saveNewCustomer} ref={form}>
                              {/* Add Third Party Customer */}
                              {addnewCustomerForm == true ? (
                                <React.Fragment>
                                  <h5>Add New Customer</h5>
                                  <div className="order-notes">
                                    <div
                                      className="checkout-form-list"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <label>
                                        Customer Name
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        type="customername"
                                        value={tpcName}
                                        onChange={onChangeTpcName}
                                        maxLength={100}
                                        id="checkout-mess"
                                      />
                                      <span className="error-span tcpNamespan">
                                        {formErrors["tpcName"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="order-notes">
                                    <div
                                      className="checkout-form-list"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <label>
                                        E-mail
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        type="customername"
                                        value={tpcEmail}
                                        onChange={onChangeTpcEmail}
                                        maxLength={100}
                                        id="checkout-mess"
                                      />
                                      <span className="error-span tcpEmailspan">
                                        {formErrors["tpcEmail"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="order-notes">
                                    <div
                                      className="checkout-form-list"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <label>
                                        Contact No
                                        <span className="required">*</span>
                                      </label>
                                      <input
                                        type="customername"
                                        value={tpcContact}
                                        onChange={onChangeTpcContact}
                                        maxLength={20}
                                        id="checkout-mess"
                                      />
                                      <span className="error-span tcpContactspan">
                                        {formErrors["tpcContact"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div
                                        className="order-notes checkout-form-list"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <label>
                                          Address Line 1
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          type="customername"
                                          value={tpcAddLine1}
                                          onChange={onChangeTpcAdd1}
                                          maxLength={60}
                                          id="checkout-mess"
                                        />
                                        <span className="error-span tcpAdd1span">
                                          {formErrors["tpcAdd1"]}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div
                                        className="order-notes checkout-form-list"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <label>Address Line 2</label>
                                        <input
                                          type="customername"
                                          value={tpcAddLine2}
                                          onChange={onChangeTpcAdd2}
                                          maxLength={60}
                                          id="checkout-mess"
                                        />
                                        <span className="error-span tcpAdd2span">
                                          {formErrors["tpcAdd2"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div
                                        className="order-notes checkout-form-list"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <label>Address Line 3</label>
                                        <input
                                          type="customername"
                                          value={tpcAddLine3}
                                          onChange={onChangeTpcAdd3}
                                          maxLength={60}
                                          id="checkout-mess"
                                        />
                                        <span className="error-span tcpAdd3span">
                                          {formErrors["tpcAdd3"]}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div
                                        className="order-notes checkout-form-list"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <label>
                                          Post Code
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          type="customername"
                                          value={tpcPostCode}
                                          onChange={onChangeTpcPostCode}
                                          maxLength={10}
                                          id="checkout-mess"
                                        />
                                        <span className="error-span tctPostCodespan">
                                          {formErrors["tpcPostCode"]}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <div
                                        className="order-notes checkout-form-list"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <label>
                                          Country
                                          <span className="required">*</span>
                                        </label>
                                        <select
                                          onChange={onChangeTcpCountry}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {countryState != null
                                            ? countryState.map((c) => {
                                                return (
                                                  <option
                                                    key={c.countryId}
                                                    value={c.countryId}
                                                  >
                                                    {c.countryName}
                                                  </option>
                                                );
                                              })
                                            : ""}
                                        </select>
                                        <span className="error-span tactCountryspan">
                                          {formErrors["tpcCountry"]}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div
                                  className="country-select"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <label>
                                    Customer <span className="required">*</span>
                                  </label>
                                  <select
                                    id="selectTpCustomer"
                                    onChange={onChangeTcpCustomerId}
                                  >
                                    <option value="0">
                                      -- Select Customer --
                                    </option>
                                    {tpCustomerState != null
                                      ? tpCustomerState.map((c) => {
                                          if (c.id == mappedTpcCustomerId) {
                                            return (
                                              <option
                                                key={c.id}
                                                value={c.id}
                                                selected
                                              >
                                                {c.content + ": " + c.content2}
                                              </option>
                                            );
                                          } else {
                                            return (
                                              <option key={c.id} value={c.id}>
                                                {c.content + ": " + c.content2}
                                              </option>
                                            );
                                          }
                                        })
                                      : ""}
                                  </select>
                                  <span className="error-span ctpCustomerIdspan">
                                    {formErrors["tpcCustomerId"]}
                                  </span>
                                </div>
                              )}

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="order-button-payment mt-20">
                                    <CheckButton
                                      type="submit"
                                      className="tp-btn-h1"
                                      ref={checkBtn}
                                    >
                                      Print
                                    </CheckButton>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="order-button-payment mt-20">
                                    {addnewCustomerForm == false ? (
                                      <a
                                        className="tp-btn-h1"
                                        onClick={(e) => {
                                          setAddnewCustomerForm(true);
                                          setFormErrors({});
                                        }}
                                      >
                                        New Customer
                                      </a>
                                    ) : (
                                      <a
                                        className="tp-btn-h1"
                                        onClick={(e) => {
                                          setAddnewCustomerForm(false);
                                          setFormErrors({});
                                        }}
                                      >
                                        Select Customer
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
