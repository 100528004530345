import React, { useState, useRef, useEffect } from "react";

import "../resources/css/login-style.css";
import "../resources/css/singup.css";

import $ from "jquery";
import { loadScript } from "../utility/loadScriptUtil";
import { countryList2GET, createSignUpUser } from "../services/apiServices";
import swal from "sweetalert2";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { useNavigate } from "react-router-dom";

const signUp = () => {
  const form = useRef();
  const checkBtn = useRef();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [countryState, setCountryState] = useState(null);

  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [addLine3, setAddLine3] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState(9);
  const [policyState, setPolicyState] = useState(false);

  const [errors, setErrors] = useState([]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onChangeCompanyName = (e) => {
    setCompanyName(e.target.value);
    $("#companyName").removeClass("borderClass");
  };

  const onChangeCompanyNumber = (e) => {
    setCompanyNumber(e.target.value);
  };

  const onChangeVatNumber = (e) => {
    setVatNumber(e.target.value);
  };

  const onChangeCountry = (e) => {
    setCountry(e.target.value);
  };

  const onChangeAddLine1 = (e) => {
    setAddLine1(e.target.value);
    $("#addLine01").removeClass("borderClass");
  };

  const onChangeAddLine2 = (e) => {
    setAddLine2(e.target.value);
  };

  const onChangeAddLine3 = (e) => {
    setAddLine3(e.target.value);
    $("#addLine03").removeClass("borderClass");
  };

  const onChangePostCode = (e) => {
    setPostCode(e.target.value);
    $("#postCode").removeClass("borderClass");
  };

  const onChangePhone = (e) => {
    setPhone(e.target.value);
    $("#phone").removeClass("borderClass");
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    $("#email").removeClass("borderClass");
  };

  const onChangePolicy = (e) => {
    if (policyState) {
      setPolicyState(false);
    } else {
      setPolicyState(true);
    }
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsgs = {};

    if (companyName == 0) {
      formIsValid = false;
      // errorMsgs["companyName"] = "Company name is required.";
      $("#companyName").addClass("borderClass");
    }

    // if (companyNumber == 0) {
    //   formIsValid = false;
    //   errorMsgs["companyNumber"] = "Company Number is required.";
    // }

    // if (vatNumber == 0) {
    //   formIsValid = false;
    //   errorMsgs["vatNumber"] = "Vat Registrations is required.";
    // }

    if (!phone) {
      formIsValid = false;
      // errorMsgs["phone"] = "Phone Number is required.";
      $("#phone").addClass("borderClass");
    }

    if (!email) {
      formIsValid = false;
      // errorMsgs["email"] = "Email Address is required.";
      $("#email").addClass("borderClass");
    }

    if (!addLine1) {
      formIsValid = false;
      // errorMsgs["addLine1"] = "Address Line 1 is required.";
      $("#addLine01").addClass("borderClass");
    }

    if (!addLine3) {
      formIsValid = false;
      // errorMsgs["addLine1"] = "Address Line 1 is required.";
      $("#addLine03").addClass("borderClass");
    }

    if (!postCode) {
      formIsValid = false;
      // errorMsgs["postCode"] = "PostCode is required.";
      $("#postCode").addClass("borderClass");
    }

    if (!policyState) {
      formIsValid = false;
    }

    setErrors(errorMsgs);
    return formIsValid;
  };

  const createUser = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setLoading(true);
      createSignUpUser(
        companyName,
        companyNumber,
        vatNumber,
        email,
        phone,
        addLine1,
        addLine2,
        addLine3,
        postCode,
        country
      )
        .then(async (res) => {
          let msg = res.data;
          if (msg["email"]) {
            let error = msg["email"];
            swal.fire({
              position: "top",
              icon: "error",
              title: "Error",
              text: error,
              showConfirmButton: false,
              timer: 3000,
            });
            setLoading(false);
          } else {
            let succMsg = msg["success"];
            swal.fire({
              position: "top",
              icon: "success",
              title: "Thank You!",
              text: succMsg,
              showConfirmButton: false,
              timer: 15000,
            });
            setLoading(false);

            await delay(15000);

            navigate("/");
            window.location.reload(true);
          }
        })
        .catch((error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(resMessage);
          setLoading(false);
        });
    }
  };

  const cleanForm = () => {
    setCompanyName("");
    setCompanyNumber("");
    setVatNumber("");
    setPhone("");
    setEmail("");
    setAddLine1("");
    setAddLine2("");
    setAddLine3("");
    setPostCode("");
    setCountry(9);
    setPolicyState(false);
  };

  //get country data
  const getCountry = async () => {
    countryList2GET()
      .then((res) => {
        setCountryState(res.data);
        loadScript();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      await getCountry();
      // loadScript();
    })();
  }, []);

  return (
    <React.Fragment>
      <div className="Main-container">
        <div
          className="container_1"
          style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
          <div className="wrap-singup">
            <div className="singup-pic">
              <img src="assets/img/logo/logo2.png" alt="IMG" />
            </div>
            <Form className="singup-form" onSubmit={createUser} ref={form}>
              <span className="singup-form-title_">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "inherit",
                    margin: "inherit",
                  }}
                >
                  B2B Trade Portal Registration
                </p>
              </span>
              <span className="singup-title" style={{ textAlign: "center" }}>
                Please enter your company details to create an account with us.
              </span>
              <div className="product-details">
                <div className="container">
                  <div className="wrap-input">
                    <input
                      type="text"
                      className="input"
                      id="companyName"
                      name="Company"
                      placeholder="Company Name*"
                      value={companyName}
                      // required
                      onChange={onChangeCompanyName}
                    />

                    <span className="focus-input"></span>
                    <span className="symbol-input">
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </span>
                  </div>

                  <div className="wrap-input">
                    <input
                      type="text"
                      className="input"
                      name="Phone Number"
                      placeholder="Phone Number*"
                      id="phone"
                      value={phone}
                      // required
                      onChange={onChangePhone}
                    />

                    <span className="focus-input"></span>
                    <span className="symbol-input">
                      <i className="fa fa-mobile" aria-hidden="true"></i>
                    </span>
                  </div>

                  <div className="row">
                    <div className="col-xl-6">
                      <div className="wrap-input">
                        <input
                          type="text"
                          className="input"
                          name="Company Number"
                          placeholder="Company Number"
                          id="companyNum"
                          value={companyNumber}
                          // required
                          onChange={onChangeCompanyNumber}
                        />

                        <span className="focus-input"></span>
                        <span className="symbol-input">
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="wrap-input">
                        <input
                          type="text"
                          className="input"
                          name="Vat Registrations"
                          placeholder="VAT Number"
                          id="vatReg"
                          value={vatNumber}
                          // required
                          onChange={onChangeVatNumber}
                        />

                        <span className="focus-input"></span>
                        <span className="symbol-input">
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div className="wrap-input">
                      <input
                        type="text"
                        className="input"
                        name="email"
                        placeholder="E-mail Address*"
                        id="email"
                        value={email}
                        // required
                        onChange={onChangeEmail}
                      />

                      <span className="focus-input"></span>
                      <span className="symbol-input">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div className="wrap-input">
                      <input
                        type="text"
                        className="input"
                        name="Address Line 1"
                        placeholder="Address Line 1*"
                        id="addLine01"
                        value={addLine1}
                        // required
                        onChange={onChangeAddLine1}
                      />

                      <span className="focus-input"></span>
                      <span className="symbol-input">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div className="wrap-input">
                      <input
                        type="text"
                        className="input"
                        name="Address Line 2"
                        placeholder="Address Line 2"
                        id="addLine02"
                        value={addLine2}
                        // required
                        onChange={onChangeAddLine2}
                      />
                      <span className="focus-input"></span>
                      <span className="symbol-input">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div className="wrap-input">
                      <input
                        type="text"
                        className="input"
                        name="Address Line 3"
                        placeholder="City*"
                        id="addLine03"
                        value={addLine3}
                        // required
                        onChange={onChangeAddLine3}
                      />
                      <span className="focus-input"></span>
                      <span className="symbol-input">
                        <i className="fa fa-map-marker " aria-hidden="true"></i>
                      </span>
                    </div>

                    <div className="wrap-input">
                      <input
                        type="text"
                        className="input"
                        name="Post Code"
                        placeholder="Post Code*"
                        id="postCode"
                        value={postCode}
                        // required
                        onChange={onChangePostCode}
                      />

                      <span className="focus-input"></span>
                      <span className="symbol-input">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div className="wrap-input">
                      <select
                        id="country_select"
                        // style={{ display: "none" }}
                        onChange={onChangeCountry}
                        value={country}
                      >
                        {countryState != null
                          ? countryState.map((c) => {
                              return (
                                <option key={c.countryId} value={c.countryId}>
                                  {c.countryName}
                                </option>
                              );
                            })
                          : ""}
                      </select>

                      <span className="focus-input"></span>
                      <span className="symbol-input">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                      </span>
                    </div>

                    <div className="wrap-input">
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: "10px",
                          lineHeight: "15px",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="inputCheck"
                          name="agree"
                          id="termCondition"
                          value={policyState}
                          // required
                          onChange={onChangePolicy}
                          style={{
                            // marginLeft: "15px",
                            marginRight: "5px",
                            verticalAlign: "bottom",
                            position: "relative",
                            top: "-1px",
                            cursor: "pointer",
                          }}
                        />
                        By submitting this form you consent to Woodalls
                        processing your data and contacting you to fulfill your
                        request. For more information on how we are committed to
                        protecting and respecting your privacy, please review
                        our {""}
                        <a
                          href="https://www.we-sell-it.co.uk/terms"
                          style={{
                            color: "#2c2e72",
                            fontWeight: "bold",
                            fontSize: "10px",
                          }}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>

                    <div className="singup-form-btn-container">
                      <button class="singup-form-btn" disabled={loading}>
                        Submit &nbsp;
                        {loading && (
                          <span className="spinner-border spiner-border-sm align-right"></span>
                        )}
                      </button>
                      <CheckButton
                        type="submit"
                        className="singup-form-btn"
                        style={{ display: "none" }}
                        ref={checkBtn}
                      >
                        Registr now
                      </CheckButton>
                      {/* <button className="singup-form-btn">Registr now</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default signUp;
