import React, { useState, useEffect } from "react";

import Header from "../components/header-component";
import Footer from "../components/footer";
import "../resources/css/brand.css";
import { getBrandData, getCategoriesByBrand } from "../services/apiServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { padding } from "@mui/system";

export default function Brands() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [categoryState, setCategoryState] = useState([]);
  const [brandState, setBrandState] = useState([]);

  //get product categories
  const categoryData = async (brandId) => {
    getCategoriesByBrand(brandId)
      .then((res) => {
        setCategoryState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get product categories
  const brandData = async (brandId) => {
    getBrandData(brandId)
      .then((res) => {
        setBrandState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      categoryData(id);
      brandData(id);
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}
      {/* <!-- about-area-start --> */}
      <div></div>
      <div className="shop-area mb-20">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="shop-banner mb-30">
                <div className="container custom-conatiner">
                  <div className="about-content" style={{ margin: "20px" }}>
                    <span>Category</span>
                  </div>
                  <div className="social-icon social-icon-2">
                    <div className="row">
                      {categoryState.map((cat) => {
                        return (
                          <div className="cat" key={cat.categoryId}>
                            <img
                              src={cat.image}
                              style={{
                                width: "150px",
                                margin: "20px",
                                paddingTop: "10px",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingBottom: "0px",
                              }}
                            />
                            <a
                              style={{ fontSize: "12px" }}
                              onClick={() => {
                                console.log(cat.categoryId);
                                navigate({
                                  pathname: "/category-product",
                                  search:
                                    "?category=" +
                                    cat.categoryId +
                                    "&brand=[" +
                                    id +
                                    "]",
                                });
                                window.location.reload(true);
                              }}
                            >
                              {cat.categoryName}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr
              style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 0.5,
                borderColor: "#000000",
              }}
            />

            <div
              className="banner-image"
              style={{ marginTop: "40px", width: "50%" }}
            >
              <img
                className="banner-l"
                style={{ marginTop: "20px" }}
                src={brandState.image}
                alt=""
              />
            </div>

            <div
              className="about-area pt-80 pb-80"
              data-background="assets/img/bg/about-bg.png"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-12">
                    <div className="about-content">
                      <span>ABOUT OUR {brandState.name} STORE</span>
                      {/* <h4>Hello,</h4>
                      <h5 className="banner-t mb-30">
                        Online for over 25 years, in store for over 55 years
                      </h5> */}
                      <p style={{ textAlign: "justify" }}>{brandState.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about-area-end --> */}
      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
