import React, { Component, useEffect } from "react";

export default function Footer() {
  return (
    <React.Fragment>
      {/* features-2__area-start */}
      {/* <section className="features-2__area d-ddark-bg">
        <div className="container custom-conatiner">
          <div className="features-2__lists pt-25 pb-25">
            <div className="row row-cols-xxl-5 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 gx-0">
              <div className="col">
                <div className="features-2__item">
                  <div className="features-2__icon mr-20">
                    <i className="fal fa-truck"></i>
                  </div>
                  <div className="features-2__content">
                    <h6>FREE DELIVERY</h6>
                    <p>For all orders over $120</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="features-2__item">
                  <div className="features-2__icon mr-20">
                    <i className="fal fa-money-check"></i>
                  </div>
                  <div className="features-2__content">
                    <h6>SAFE PAYMENT</h6>
                    <p>100% secure payment</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="features-2__item">
                  <div className="features-2__icon mr-20">
                    <i className="fal fa-comments-alt"></i>
                  </div>
                  <div className="features-2__content">
                    <h6>24/7 HELP CENTER</h6>
                    <p>Delicated 24/7 support</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="features-2__item">
                  <div className="features-2__icon mr-20">
                    <i className="fal fa-hand-holding-usd"></i>
                  </div>
                  <div className="features-2__content">
                    <h6>SHOP WITH CONFIDENCE</h6>
                    <p>If goods have problems</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="features-2__item">
                  <div className="features-2__icon mr-20">
                    <i className="fad fa-user-headset"></i>
                  </div>
                  <div className="features-2__content">
                    <h6>FRIENDLY SERVICES</h6>
                    <p>30 day satisfaction guarantee</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* features-2__area-end */}

      {/* footer-start */}
      <footer>
        <div className="fotter-area d-ddark-bg">
          {/* <div className="footer__top pt-60 pb-10">
            <div className="container custom-conatiner">
              <div className="row">
                <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-8">
                  <div className="footer__widget footer-col-2">
                    <div className="footer__widget-title">
                      <h4>About Woodalls</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__link footer__link-2">
                        <ul>
                          <li>
                            <a href="#.html">About Us</a>
                          </li>
                          <li>
                            <a href="#.html">Milestones</a>
                          </li>
                          <li>
                            <a href="#.html">Board Of Directors</a>
                          </li>
                          <li>
                            <a href="#.html">Senior Management Team</a>
                          </li>
                          <li>
                            <a href="#.html">Career Opportunities &amp;</a>
                          </li>
                          <li>
                            <a href="#.html">Investor Relations</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div className="footer__widget footer-col-2">
                    <div className="footer__widget-title">
                      <h4>Quick Links</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__link footer__link-2">
                        <ul>
                          <li>
                            <a href="#">Contact Us</a>
                          </li>
                          <li>
                            <a href="#">Locations</a>
                          </li>
                          <li>
                            <a href="#">Hot Deals</a>
                          </li>
                          <li>
                            <a href="#">Stock Clearance Sales</a>
                          </li>
                          <li>
                            <a href="#">HP Installment Payments</a>
                          </li>
                          <li>
                            <a href="#">Redeem Voucher</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div className="footer__widget footer-col-2">
                    <div className="footer__widget-title">
                      <h4>Support</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__link footer__link-2">
                        <ul>
                          <li>
                            <a href="#">Products</a>
                          </li>
                          <li>
                            <a href="#">Brands</a>
                          </li>
                          <li>
                            <a href="#">Adds Services</a>
                          </li>
                          <li>
                            <a href="#">Payment Methods</a>
                          </li>
                          <li>
                            <a href="#">Check Loyalty Card Point Balance</a>
                          </li>
                          <li>
                            <a href="#">Privacy Policy</a>
                          </li>
                          <li>
                            <a href="#">Terms And Conditions</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="footer__widget footer-col-2">
                    <div className="footer__widget-title">
                      <h4>Media</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__link footer__link-2">
                        <ul>
                          <li>
                            <a href="#">Accolades</a>
                          </li>
                          <li>
                            <a href="#">Like Us On Facebook</a>
                          </li>
                          <li>
                            <a href="#">YouTube - Product Demo Videos</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6">
                  <div className="footer__widget">
                    <div className="footer__widget-title mb-20">
                      <h4>Download App</h4>
                    </div>
                    <div className="footer__widget-content">
                      <p className="footer-text mb-25">
                        Woodalls App is now available on App Store & Google
                        Play. Get it now.
                      </p>
                      <div className="apps-store mb-20">
                        <a href="#">
                          <img src="assets/img/brand/app_ios.png" alt="" />
                        </a>
                        <a href="#">
                          <img src="assets/img/brand/app_android.png" alt="" />
                        </a>
                      </div>
                      <div className="social-icon social-icon-2">
                        <a href="#" className="facebook">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" className="twitter">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="#" className="youtube">
                          <i className="fab fa-youtube"></i>
                        </a>
                        <a href="#" className="linkedin">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                        <a href="#" className="rss">
                          <i className="fas fa-rss"></i>
                        </a>
                        <a href="#" className="dribbble">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="footer__bottom-2">
            <div className="container custom-conatiner">
              <div className="footer__bottom-content footer__bottom-content-2 pt-50 pb-50">
                <div className="row">
                  <div className="col-xl-12">
                    {/* <div className="footer__links footer__links-d text-center mb-25">
                      <p>
                        <a href="#">About Us</a>
                        <a href="#">Delivery & Return</a>
                        <a href="#">Privacy Policy</a>
                        <a href="#">Help</a>
                        <a href="#">Order Tracking</a>
                        <a href="#">Contact Us</a>
                        <a href="#">FAQs </a>
                      </p>
                    </div> */}
                    {/* <div className="payment-image text-center mb-25">
                      <a href="#">
                        <img src="assets/img/payment/payment.png" alt="" />
                      </a>
                    </div> */}
                    <div className="copy-right-area copy-right-area-2 text-center">
                      <p>Copyright © 2022 Woodalls All Rights Reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer-end */}
    </React.Fragment>
  );
}
