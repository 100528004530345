import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import jQuery from 'jquery';
import $ from 'jquery';

// link js 
//import '../src/resources/js/vendor/jquery.js';
//import '../src/resources/js/meanmenu.js';
// import '../src/assets/js/swiper-bundle.js';
// import '../src/assets/js/owl-carousel.js';
// import '../src/assets/js/magnific-popup.js';
// import '../src/assets/js/countdown.min.js';
// import '../src/assets/js/counterup.js';
// import '../src/assets/js/wow.js';
// import '../src/assets/js/backtotop.js';
// import '../src/assets/js/nice-select.js';
// import '../src/assets/js/isotope-pkgd.js';
// import '../src/assets/js/imagesloaded-pkgd.js';
// import '../src/assets/js/main.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <App /> );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
