import React, { useState, useEffect } from "react";
import "../resources/css/style.css";
import Header from "../components/header-component";
import Footer from "../components/footer";
export default function contactUs() {
  return (
    <React.Fragment>
      <Header />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="about-content">
              <div className="location-area pt-70 pb-25">
                <div className="container">
                  <div className="row mb-25">
                    <div className="col-xl-12">
                      <div className="abs-section-title text-center">
                        <span>LOCATION STORE</span>
                        <h4>Where We Are</h4>
                        {/* <p>
                          The perfect way to enjoy brewing tea on low hanging
                          fruit to identify. Duis autem vel eum iriure dolor in
                          hendrerit in vulputate velit esse molestie consequat,
                          vel illum dolore eu feugiat nulla facilisis.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="location-item mb-30">
                        <h4>Woodhall Refrigeration Ltd</h4>
                        <br></br>
                        <div className="sm-item-loc sm-item-border mb-20">
                          <div className="sml-icon mr-20">
                            <i className="fal fa-map-marker-alt"></i>
                          </div>
                          <div className="sm-content">
                            <span>Find us</span>
                            <p>
                              1053-1055 Finchley Road, London, NW11 7ES,United
                              Kingdom
                            </p>
                          </div>
                        </div>
                        <div className="sm-item-loc sm-item-border mb-20">
                          <div className="sml-icon mr-20">
                            <i className="fal fa-phone-alt"></i>
                          </div>
                          <div className="sm-content">
                            <span>Call us</span>
                            <p>
                              <a href="tel:+442084553841">(+44) 20 8455 3841</a>
                            </p>
                          </div>
                        </div>
                        <div className="sm-item-loc  sm-item-border mb-20">
                          <div className="sml-icon mr-20">
                            <i className="fal fa-globe"></i>
                          </div>
                          <div className="sm-content">
                            <span>web</span>
                            <p>
                              <a href="http://www.woodalls-trade.co.uk">
                                www.woodalls-trade.co.uk
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="sm-item-loc  sm-item-border mb-20">
                          <div className="sml-icon mr-20">
                            <i className="fal fa-envelope"></i>
                          </div>
                          <div className="sm-content">
                            <span>Mail us</span>
                            <p>
                              <a href="mailto:trade@woodalls.co.uk">
                                trade@woodalls.co.uk
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="cmamps-area">
                        <iframe
                          src="https://maps.google.com/maps?q=1053 Finchley Rd, London NW11 7ES, United Kingdom&t=&z=10&ie=UTF8&iwloc=&output=embed"
                          width={1000}
                          height={500}
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
