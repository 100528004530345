import React, { useState, useEffect } from "react";
import axios from "axios";
import UserService from "../services/user.service";
import { Link } from "react-router-dom";
import cart from "../common/cart";
import useCartStore from "../common/globalStore";

export default function MostPopularItem() {
  const [popularItemState, setPopularItemState] = useState([]);
  const getCartData = useCartStore((state) => state.updateCart);

  const popularItemData = async () => {
    const res = await axios
      .get(
        UserService.api_Url +
          "api/app/product/topSellingProduct/" +
          UserService.user.customerId,
        { headers: UserService.header }
      )
      .catch((err) => {
        console.log(err);
      });
    setPopularItemState(res.data);
  };

  //add to cart function
  function fnAddToCart(productId, modelId, qty, price) {
    cart.addToCart(productId, modelId, qty, price);
    setTimeout(
      function () {
        //Start the timer
        getCartData();
      }.bind(this),
      1000
    );
  }

  useEffect(() => {
    popularItemData();
  }, []);

  return (
    <React.Fragment>
      <section className="trending-product-area light-bg-s pt-25 pb-15">
        <div className="container custom-conatiner">
          <div className="row">
            <div className="col-xl-12">
              <div className="section__head d-flex justify-content-between mb-30">
                <div className="section__title section__title-2">
                  <h5 className="st-titile">Most Popular</h5>
                </div>
                <div className="button-wrap button-wrap-2">
                  {/* <a href="#">
                    See All Product <i className="fal fa-chevron-right"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {popularItemState.map((pi) => {
              let price =
                pi.variantCount === 0
                  ? pi.specialPrice === 0
                    ? pi.orginalPrice
                    : pi.specialPrice
                  : 0;
              return (
                <div
                  key={pi.productId + "@" + pi.modelId}
                  className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2"
                >
                  <div className="product__item product__item-2 b-radius-2 mb-20">
                    <div className="product__thumb fix">
                      <div className="product-image w-img">
                        <Link to={"/product/" + pi.sku}>
                          <img src={pi.defaultImage} alt="product" />
                        </Link>
                      </div>
                      <div className="product__offer">
                        {/* <span className="discount">-15%</span> */}
                      </div>
                      <div className="product-action product-action-2">
                        <Link
                          to={"/product/" + pi.sku}
                          className="icon-box icon-box-1"
                        >
                          <i className="fal fa-eye"></i>
                          <i className="fal fa-eye"></i>
                        </Link>
                        {/* <a href="#" className="icon-box icon-box-1">
                          <i className="fal fa-heart"></i>
                          <i className="fal fa-heart"></i>
                        </a>
                        <a href="#" className="icon-box icon-box-1">
                          <i className="fal fa-layer-group"></i>
                          <i className="fal fa-layer-group"></i>
                        </a> */}
                      </div>
                    </div>
                    <div className="product__content product__content-2">
                      <h6>
                        <Link to={"/product/" + pi.sku}>
                          {pi.productName}
                          {pi.variantCount > 0 ? " (Variants) " : ""}
                        </Link>
                      </h6>
                      {pi.tempStop == true ? (
                        <span className="status_font">
                          Temporary not available
                        </span>
                      ) : (
                        ""
                      )}
                      <div className="price">
                        {pi.variantCount === 0 ? (
                          <span>{UserService.numberFormat(price)}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {pi.tempStop == false && pi.variantCount === 0 ? (
                      <div className="product__add-cart text-center">
                        <button
                          type="button"
                          className="cart-btn-3 product-modal-sidebar-open-btn d-flex align-items-center justify-content-center w-100"
                          onClick={() =>
                            fnAddToCart(pi.productId, pi.modelId, 1, price)
                          }
                        >
                          Add to Cart
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
