import React, { useState, useRef, useEffect } from "react";

import "../resources/css/email.css";
import { RiseLoader } from "react-spinners";
import { unlockUserAccount } from "../services/apiServices";

export default function UnlockAccountEmailVerification() {
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("Locked");
  const [message, setMessage] = useState("");
  const [showImage, setShowImage] = useState(false);

  //unlock account
  const unlockedAccountFn = async () => {
    unlockUserAccount(token)
      .then((res) => {
        if (res.data.status == 1) {
          setTitle("Unlocked");
          setShowImage(true);
        }
        setMessage(res.data.message);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      await unlockedAccountFn();
    })();
  }, []);

  return (
    <React.Fragment>
      {!loading ? (
        <div className="spinner">
          <RiseLoader color="#263c97" size={20} />
        </div>
      ) : (
        <div className="Main-container">
          <div className="container_1">
            <div className="emailstyle">
              <div className="emailpic">
                {showImage == true ? (
                  <img
                    src="assets/img/icon/unlocked.png"
                    alt="IMG"
                    className="center"
                    style={{ maxWidth: "150px" }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="maintext">{title}</div>
              <div className="subtext"> {message}</div>
              <div className="email-form-btn-container">
                <a href="/" className="email-form-btn">
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
