import React, { useState, useEffect } from "react";

import Header from "../components/header-component";
import Footer from "../components/footer";

export default function terms() {
  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {/* <!-- about-area-start --> */}
      <div
        className="about-area pt-80 pb-80"
        data-background="assets/img/bg/about-bg.png"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-content">
                <span>TERMS AND CONDITIONS OUR ONLINE STORE</span>
                <h4>Legal Statement</h4>
                <p style={{ textAlign: "justify" }}>
                  Woodall Refrigeration Ltd keeps the present site ("Site") for
                  your personal education, information, communication and
                  entertainment. Please enjoy browsing our Site as you are
                  pleased. However, downloading material displayed on the Site
                  is only allowed for non-commercial, personal use and provided
                  you also retain all copyright and other propriety notices
                  contained in the materials. It is strictly forbidden to
                  distribute, modify, transmit, reuse, report, or use the
                  contents of the Site for public or commercial purposes,
                  including the text, images, audio, and video without the prior
                  written approval of Woodall Refrigeration Ltd. Your access and
                  use of the Site is subject to the following terms and
                  conditions ("Terms and Conditions") as well as to all the
                  applicable laws. By accessing and browsing the Site, you
                  agree, without any limitation or qualification whatsoever, to
                  the Terms and Conditions.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-image w-img">
                <img src="../assets/img/logo/logo2.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <h4>Terms and Conditions</h4>
            <div
              className="product__details-content"
              style={{ textAlign: "justify" }}
            >
              <div className="features-des mb-20 mt-10">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fas fa-circle"></i>The content of the Site
                      is entirely copyright and/or trademark protected (unless
                      otherwise specified or marked) and may not be used other
                      than provided in these Terms and Conditions without the
                      prior express written approval of Woodall Refrigeration
                      Ltd. Further thereto, Woodall Refrigeration Ltd neither
                      warrants nor represents that your browsing of materials
                      displayed on the Site will not infringe any rights of
                      third parties not owned by or affiliated with Woodall
                      Refrigeration Ltd.
                    </a>
                  </li>
                  <br></br>
                  <li>
                    <a href="#">
                      <i className="fas fa-circle"></i>Woodall Refrigeration Ltd
                      uses all reasonable efforts to provide accurate and
                      up-to-date information on the Site. However, Woodall
                      Refrigeration Ltd makes no warranties or representations
                      as to the accuracy of the Site and assumes no liability or
                      responsibility whatsoever for any errors or omissions in
                      the content on the Site.
                    </a>
                  </li>
                  <br></br>
                  <li>
                    <a href="#">
                      <i className="fas fa-circle"></i>Browsing our Site is at
                      your own risk. Neither Woodall Refrigeration Ltd nor any
                      other party involved in creating, producing, or delivering
                      the Site is liable for any direct, incidental,
                      consequential, indirect, or punitive damages arising out
                      of your access to, or use of, the Site. WITHOUT LIMITING
                      THE FOREGOING, EVERYTHING ON THE SITE IS PROVIDED TO YOU
                      "AS IS" WITHOUT WARRANTY OF ANY KIND WHATSOEVER, EITHER
                      EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                      PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Woodall
                      Refrigeration Ltd further declines any responsibility and
                      liability for any damages to, or viruses that may infect
                      your computer equipment or other property on account of
                      your access to, use of, or browsing in the Site or your
                      downloading of any materials, including, but not limited
                      to, data, text, images, video, or audio from the Site
                    </a>
                  </li>
                  <br></br>
                  <li>
                    <a href="#">
                      <i className="fas fa-circle"></i>Any communication or
                      material you transmit to the Site by electronic mail or
                      otherwise, including but not limited to any data,
                      questions, comments, suggestions, and the like, will be
                      treated as non-confidential and non-proprietary. Anything
                      you transmit or post may be used by Woodall Refrigeration
                      Ltd or its affiliates for any purpose, including but not
                      limited to reproduction, disclosure, transmission,
                      publication, broadcast, and posting. Furthermore, Woodall
                      Refrigeration Ltd is free to use any ideas, concepts,
                      know-how, or techniques contained in any communication you
                      send to the Site for any purpose whatsoever, including but
                      not limited to developing, manufacturing, and marketing
                      products using such information.
                    </a>
                  </li>
                  <br></br>
                  <li>
                    <a href="#">
                      <i className="fas fa-circle"></i>Any and all trademarks,
                      logos, and service marks (collectively the "Trademarks")
                      displayed on the Site, are registered and/or unregistered
                      Trademarks of Woodall Refrigeration Ltd and/or its
                      affiliated companies. Nothing contained on the Site should
                      be construed as granting, by implication, estoppel, or
                      otherwise, any license or right to use any Trademark
                      displayed on the Site without the prior express written
                      permission of Woodall Refrigeration Ltd. Any use of the
                      Trademarks, or any other content of the Site, except as
                      provided in these Terms and Conditions, is strictly
                      prohibited. Please be herewith advised that Woodall
                      Refrigeration Ltd will, in case of any infringement by you
                      or any third parties aggressively enforce its intellectual
                      property rights to the fullest extent of the law and
                      herewith fully reserves any and all rights in this
                      respect.
                    </a>
                  </li>
                  <br></br>
                  <li>
                    <a href="#">
                      <i className="fas fa-circle"></i>Please furthermore note
                      that Woodall Refrigeration Ltd has not reviewed all of the
                      sites linked to the Site and is not responsible for the
                      contents of any off-site pages or any other sites linked
                      to the Site. Your linking to any other off-site pages or
                      other sites is at your own risk.
                    </a>
                  </li>
                  <br></br>
                  <li>
                    <a href="#">
                      <i className="fas fa-circle"></i>Woodall Refrigeration Ltd
                      may at any time revise and update these Terms and
                      Conditions. Please note that you arelikewise obligated by
                      any such revisions and should therefore periodically visit
                      this page to review the then current Terms and Conditions
                      to which you are bound.
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about-area-end --> */}
      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
