import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FadeLoader, PulseLoader, RiseLoader } from "react-spinners";
import swal from "sweetalert";

import Header from "../components/header-component";
import Footer from "../components/footer";
import { salesDeliveryDetailsGET } from "../services/apiServices";

import "../resources/css/viewoder.css";

export default function deliveryDetails() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [deliveriyDataState, setDeliveryDataState] = useState(null);
  const [cusAddress, setCusAddress] = useState(false);
  const [items, setItems] = useState(false);

  //get delivery data
  const getDeliveryData = async (dispatchId) => {
    salesDeliveryDetailsGET(dispatchId)
      .then((res) => {
        const row = res.data;

        if (row.orderNo != "") {
          setDeliveryDataState(row);
          setCusAddress(row.deliveryAddress);
          setItems(row.items);
        }

        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    (async () => {
      await getDeliveryData(id);
    })();
  }, []);

  return (
    <React.Fragment>
      {/* Header Component start */}
      <Header />
      {/* Header Component end */}

      {/* breadcrumb__area-start */}
      <section className="breadcrumb__area box-plr-75">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/my-account">My Account</a>
                    </li>
                    <li className="breadcrumb-item">Order</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Delivery Details
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb__area-end */}

      {!loading ? (
        <div className="spinner">
          <RiseLoader color="#263c97" size={20} />
        </div>
      ) : (
        <main>
          <div className="account-area mb-70">
            <div className="container">
              {deliveriyDataState != null ? (
                <div className="row">
                  <h5>Delivery Details</h5>
                  <div className="col-md-4">
                    <div class="your-order-table table-responsive">
                      <table>
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name">
                              Reference No
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.orderNo}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Dispatch
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.dispatchId}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Customer Phone
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.customerPhone}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="your-order-table table-responsive">
                      <table>
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name">
                              Dispatch By
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.dispatchBy}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Delivery Contact
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.deliveryContact != ""
                                  ? deliveriyDataState.deliveryContact
                                  : "N/A"}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Delivery Phone
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.deliveryPhone != ""
                                  ? deliveriyDataState.deliveryPhone
                                  : "N/A"}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div class="your-order-table table-responsive">
                      <table>
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name">
                              Dispatch Date
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.dispatchDate}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Delivery Method
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.deliveryMethod}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Delivery Note
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.deliveryNote != "" ? (
                                  <a
                                    href={deliveriyDataState.deliveryNote}
                                    target="_blank"
                                    style={{ color: "green" }}
                                  >
                                    {deliveriyDataState.deliveryNoteLabel}
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* 2nd row*/}
                  <hr className="solid"></hr>
                  <div className="col-md-4">
                    <div class="your-order-table table-responsive">
                      <table>
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name">
                              Care of
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.careOf}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Status
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.status}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="your-order-table table-responsive">
                      <table>
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name" style={{ width: "53%" }}>
                              Delivery Type
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.deliveryType}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Vehicle
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.vehicle != null
                                  ? deliveriyDataState.vehicle
                                  : "N/A"}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div class="your-order-table table-responsive">
                      <table>
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name" style={{ width: "46%" }}>
                              Contact Name
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.contactName}
                              </span>
                            </td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">
                              Contact Phone
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {deliveriyDataState.contactPhone}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr className="solid"></hr>
                  <div className="col-md-4">
                    <div class="your-order-table table-responsive">
                      <table>
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name">
                              Customer Address
                              <strong class="product-quantity float-right">
                                :
                              </strong>
                            </td>
                            <td class="product-total">
                              <span class="amount span-font-css">
                                {cusAddress.line01}
                                {cusAddress.line02 != null ? <br /> : ""}
                                {cusAddress.line02 != null
                                  ? cusAddress.line02
                                  : ""}
                                {cusAddress.line03 != null ? <br /> : ""}
                                {cusAddress.line03 != null
                                  ? cusAddress.line03
                                  : ""}
                                <br />
                                {cusAddress.postCode}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="">
                      <div class="your-order-table table-responsive">
                        <table>
                          <tbody>
                            <tr class="cart_item">
                              <td class="product-name" style={{ width: "20%" }}>
                                Customer Reference
                                <strong class="product-quantity float-right">
                                  :
                                </strong>
                              </td>
                              <td class="product-total">
                                <span class="amount span-font-css">
                                  {deliveriyDataState.customerRef}
                                </span>
                              </td>
                            </tr>
                            <tr class="cart_item">
                              <td class="product-name" style={{ width: "25%" }}>
                                Instrucation
                                <strong class="product-quantity float-right">
                                  :
                                </strong>
                              </td>
                              <td class="product-total">
                                <span class="amount span-font-css">
                                  {deliveriyDataState.instructions}
                                </span>
                              </td>
                            </tr>
                            <tr class="cart_item">
                              <td class="product-name" style={{ width: "25%" }}>
                                Remark
                                <strong class="product-quantity float-right">
                                  :
                                </strong>
                              </td>
                              <td class="product-total">
                                <span class="amount span-font-css">
                                  {deliveriyDataState.remarks}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr className="solid" />
                  <div className="col-md-12">
                    <div>
                      <div
                        className="table-content table-responsive"
                        style={{ margin: "10px" }}
                      >
                        <table
                          className="table"
                          style={{ marginBottom: "10px" }}
                        >
                          <thead>
                            <tr>
                              <th className="sku">SKU</th>
                              <th className="productname">Product Name</th>
                              <th className="qty">QTY</th>
                              <th className="reference">Reference</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items != null ? (
                              items.map((el) => {
                                let productName = "";
                                if (el.model != null) {
                                }
                                return (
                                  <tr key={el.dispatchItemId}>
                                    <td className="Sku">
                                      <a>
                                        {el.model != null
                                          ? el.model.sku
                                          : el.productDetails.sku}
                                      </a>
                                    </td>
                                    <td className="productname">
                                      <a>
                                        {el.model != null
                                          ? el.model.name
                                          : el.productDetails.productName}
                                      </a>
                                    </td>
                                    <td className="qty">
                                      <a>{el.quantity}</a>
                                    </td>
                                    <td className="reference">
                                      <a>{el.comment}</a>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={4}>No records available!</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </main>
      )}

      {/* Footer Component start */}
      <Footer />
      {/* Footer Component end */}
    </React.Fragment>
  );
}
